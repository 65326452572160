<template>
  <v-row justify="center">
    <v-dialog content-class="loader" v-model="dialog" width="600px" persistent>
      <p>Génération du rapport, veuillez patienter...</p>
      <div class="loader_content">
        <v-progress-circular
          indeterminate
          color="#192646"
          size="90"
        ></v-progress-circular>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DialogLoaderComponent",
  props: ["dialog"],
};
</script>

<style>
.v-dialog__content .v-dialog.loader {
  height: 300px;
  padding: 20px;
  background-color: white;
  overflow-y: hidden;
}

.v-dialog__content .v-dialog.loader .loader_content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>