<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on">{{ btn_text }}</v-btn>
    </template>
    <v-card>
      <v-card-title>{{ titre }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="objectifForm" @submit.prevent="saveForm">
            <div>
              <div v-if="$v.nomObjectif.$dirty">
                <div class="error" v-if="!$v.nomObjectif.required">
                  Ce champ est requis
                </div>
              </div>
              <v-text-field
                v-model.trim="$v.nomObjectif.$model"
                label="Nom objectif"
              ></v-text-field>
            </div>
            <div v-if="edit">
              <div v-if="$v.ordre.$dirty">
                <div class="error" v-if="!$v.ordre.required">
                  Ce champ est requis
                </div>
                <div class="error" v-if="!$v.ordre.numeric">
                  Veuillez entrer une valeur numérique
                </div>
              </div>
              <v-text-field
                v-model="$v.ordre.$model"
                label="Ordre"
                type="number"
                min=1
              ></v-text-field>
            </div>
            <div>
              <div v-if="$v.indicateur.$dirty">
                <div class="error" v-if="!$v.indicateur.required">
                  Ce champ est requis
                </div>
              </div>
              <v-text-field
                v-model="$v.indicateur.$model"
                label="Indicateur"
              ></v-text-field>
            </div>
            <v-row>
              <v-col><v-btn type="submit">Enregistrer</v-btn></v-col>
              <v-col> <v-btn @click="close">Fermer</v-btn></v-col></v-row
            >
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required,requiredIf,numeric } from "vuelidate/lib/validators";
export default {
  name: "ObjectifFormComponent",
  props: ["projet_id", "objectif_id", "btn_text", "titre", "edit"],
  data: () => ({
    dialog: false,
    objectif: null,
    nomObjectif: "",
    ordre:null,
    indicateur: "",
  }),
  validations: {
    nomObjectif: {
      required,
    },
     ordre:{
      required:requiredIf(function(){
        return this.edit
      }),
      numeric
    },
    indicateur: {
      required,
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    ...mapActions(["addObjectif", "updateObjectif"]),
    ...mapGetters(["getObjectif"]),
    editLoadObjectif() {
      var objectif = this.objectif;
      this.nomObjectif = objectif.nom_objectif;
      this.indicateur = objectif.indicateur;
      this.ordre=objectif.ordre
    },
    saveForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (!this.$props.edit) {
          this.addNew();
        } else {
          this.update();
        }
        this.close();
      }
    },
    addNew() {
      var objectif = {
        nom_objectif: this.nomObjectif,
        indicateur: this.indicateur,
        projet: this.projet_id,
      };
      this.addObjectif(objectif);
      this.close();
    },
    update() {
      this.objectif.nom_objectif = this.nomObjectif;
      this.objectif.indicateur = this.indicateur;
      this.objectif.ordre=this.ordre
      this.updateObjectif(this.objectif);
      this.$emit("updated")
    },
    close() {
      this.$refs.objectifForm.reset();
      this.$v.$reset();
      this.dialog = false;
    },
    open() {
      if (this.$props.edit == true) {
        this.objectif = this.getObjectif()(this.objectif_id);
        this.editLoadObjectif();
      }
    },
  },
};
</script>