<template>
  <div class="col-md-6" style="margin: 0 auto">
    <h1>Réinitialiser mot de passe</h1>
    <div v-if="!success">
      <p>
        Veuillez entrer votre adresse email. Un lien vous sera envoyé à cette
        adresse afin de procéder à la réinitialisation de votre mot de passe
      </p>
      <v-form @submit.prevent="submitForm">
        <div v-if="$v.email.$dirty">
          <v-alert class="error" v-if="!$v.email.required">
            Ce champ est requis
          </v-alert>
          <v-alert class="error" v-if="!$v.email.email">
            Veuillez entrer une adresse mail valide
          </v-alert>
        </div>
        <v-text-field v-model="$v.email.$model" label="Email"></v-text-field>
        <v-btn type="submit">Envoyer</v-btn>
      </v-form>
    </div>
    <div v-if="success">
      <br/>
      <br/>
      <v-alert class="success">Un lien vous a été envoyé a l'adresse <span class="email-bold">{{email}}</span> pour procéder à la réinitialisation de votre mot de passe</v-alert>
    </div>
    <div v-if="error">
      <br/>
      <br/>
      <v-alert class="error">Erreur lors de la tentative de réinitialisation du mot de passe</v-alert>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  name: "PasswordResetComponent",
  data: () => ({
    email: "",
    success: false,
    error:false
  }),
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    ...mapActions(["resetPasswordDemand"]),
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var data={"email":this.email}
        this.resetPasswordDemand(data).then((result)=>{
          if(result)
          {
            this.success=true
          }
          else
          {
            this.error=true
          }
        })
      }
    },
  },
};
</script>

<style scoped>
.email-bold
{
  font-weight: bold;
}
</style>