import axios from "axios"
import { rappelsUrl, rappelUrl } from "../urls"

const state = {
    rappels: [],
}

const getters = {
    getRappels: (state) => {
        return state.rappels
    },
    getRappel: (state) => (rappel_id) => {
        return state.rappels.find((r) => r.id == rappel_id);
    },
}

const actions = {
    async fetchRappels({ commit }, projet_id) {
        var url = rappelsUrl.replace("{id}", projet_id)
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setRappels", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null
        });
        return reponse;
    },

    async addRappel({ commit }, rappel) {
        var projet_id = rappel.projet
        var url = rappelsUrl.replace("{id}", projet_id)
        return axios.post(url, rappel).then((response) => {
            if (response.status == 201) {
                var rappel = response.data
                commit("addRappel", rappel)
                return true
            }
        }).catch(error => {
            console.log(error)
            return false
        });
    },

    deleteRappel: function({ commit }, rappel) {
        var url = rappelUrl.replace("{id}", rappel.id);
        axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteRappel", rappel);
            }
        });
    }
}
const mutations = {
    setRappels: (state, rappels) => { state.rappels = rappels },
    addRappel: (state, rappel) => {
        state.rappels.push(rappel);
    },
    deleteRappel: (state, rappel) => {
        var rappelIndex = state.rappels.findIndex(r => r.id == rappel.id);
        state.rappels.splice(rappelIndex, 1);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}