<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon small>
        <v-icon>mdi-swap-vertical-bold</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Convertir tâche en activité</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="TacheToActivite" @submit.prevent="saveForm">
            <div>
              <div v-if="$v.objectifALier.$dirty">
                <div class="error" v-if="!$v.objectifALier.required">
                  Ce champ est requis
                </div>
              </div>
              <v-select label="Objectif auquel lier la nouvelle activité" :items="objectifsAvailableItems"
                item-text="nom_objectif" item-value="id" v-model="$v.objectifALier.$model"
                no-data-text="Aucune activité disponible"></v-select>
            </div>
            <v-row>
              <v-col>
                <v-btn type="submit">Enregistrer</v-btn>
              </v-col>
              <v-col>
                <v-btn @click="close">Fermer</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
    <ConfirmationPromptComponent ref="confirmationPrompt" :titre="promptTitle" v-bind:text="promptText"
      v-bind:callback="promptCallback"></ConfirmationPromptComponent>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from 'vuex';
import ConfirmationPromptComponent from "../Dialog/ConfirmationPromptComponent";
export default {
  name: "TacheToActiviteFormComponent",
  components: { ConfirmationPromptComponent },
  props: ["tache","objectif_id"],
  computed: {
    objectifsAvailableItems() {
      return this.getObjectifs()
    },
  },
  data: () => ({
    objectifALier: null,
    dialog: false,
    promptCallback: null,
    promptText: "",
    promptTitle: "",
  }),
  methods: {
    ...mapActions(["convertTacheToActivite"]),
    ...mapGetters([["getObjectifs"]]),
    saveForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.convert()
      }
    },

    convert() {
      this.promptTitle = "Confirmer conversion"
      this.promptText = "Convertir la tâche " + this.tache.nom_tache + " en activité ?";
      this.promptCallback = async () => {
        let payload = { "new_linked_objectif_id":this.objectifALier}
        let data = { payload: payload, tache: this.tache}
        let result = await this.convertTacheToActivite(data);
        if (result)
          this.close()
      };
      this.$refs.confirmationPrompt.dialog = true;
    },

    close() {
      this.objectifALier=null
      this.$v.$reset();
      this.dialog = false;
    },
  },
  validations: {
    objectifALier: {
      required,
    },
  },
};
</script>