<template>
<div v-if="dataFetched" class="col-md-6" style="margin:0 auto">
    <v-alert v-if="!allowed" dense type="error">Vous n'avez pas accès a ce projet</v-alert>
</div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
export default {
    name:"ProjetAuthorization",
    props:["projet_id"],
    data:()=>({
        dataFetched:false,
        allowed:false
    }),
    methods:{
        ...mapGetters(["getUser","isAdmin"]),
        ...mapActions(["fetchUserAssignations"])
    },
    created(){
        if(this.isAdmin())
        {
            this.allowed=true
            this.$emit("isAllowed",this.allowed)
        }
        else
        {
        this.fetchUserAssignations(this.getUser()).then((assignations)=>{
            var index=assignations.findIndex(a=>a.projet==this.projet_id)
            if(index!=-1)
            {
                this.allowed=true
            }
            this.dataFetched=true
            this.$emit("isAllowed",this.allowed)
        })
        }
    }
}
</script>