import axios from "axios"

export default function setupAxios() {

    // axios.defaults.xsrfHeaderName="X-CSRFToken"
    // axios.defaults.xsrfCookieName="csrftoken"
    // axios.defaults.withCredentials=true

    axios.interceptors.request.use((config) => {
        config.xsrfHeaderName = "X-CSRFToken"
        config.xsrfCookieName = "csrftoken"
        config.withCredentials = true
        return config
    }, (error) => {
        return Promise.reject(error);
    });
}