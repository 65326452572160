<template>
  <div>
    <div>
      <v-data-table
        v-if="dataFetched"
        :items="salons"
        :headers="_headers"
        locale="fr"
        :hide-default-footer="isRapportMode()"
        :disable-pagination="isRapportMode()"
        no-data-text="Aucune donnée disponible"
        no-results-text="Aucun résultat correspondant"
        :footer-props="{
          'items-per-page-text': 'Lignes par page:',
          'page-text': '{0}-{1} sur {2}',
          'items-per-page-all-text': 'Toutes',
      }"
      >
        <template #item.reservation="{ item }">
          <div :class="reservationClass(item.reservation)">
            {{ item.reservation | reservationLabel }}
          </div>
        </template>
        <template #item.actions="{ item }">
          <td class="print-hide">
            <SalonsFormComponent
              btn_text="Modifier"
              titre="Modifier salon"
              :edit="true"
              :salon_id="item.id"
              :projet_id="projet_id"
            ></SalonsFormComponent>
            <v-btn v-if="isAdmin()" icon color="red" @click="salonDelete(item)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </td>
        </template>
      </v-data-table>
    </div>
    <div v-if="!isRapportMode()" class="print-hide">
      <br />
      <br />
      <v-divider></v-divider>
      <br />
      <div>
        <SalonsFormComponent
          btn_text="Ajouter Salon"
          titre="Ajouter Salon"
          :edit="false"
          :projet_id="projet_id"
        ></SalonsFormComponent>
      </div>
      <div>
        <ConfirmationPromptComponent
          ref="confirmationPrompt"
          titre="Confirmer suppression"
          v-bind:text="promptText"
          v-bind:callback="promptCallback"
        ></ConfirmationPromptComponent>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SalonsFormComponent from "./SalonsFormComponent";
import ConfirmationPromptComponent from "../Dialog/ConfirmationPromptComponent.vue";
export default {
  name: "SalonsTableComponent",
  components: { SalonsFormComponent, ConfirmationPromptComponent },
  data: () => ({
    promptCallback: null,
    promptText: "",
    dataFetched: false,
  }),
  methods: {
    ...mapGetters(["getSalons", "isAdmin"]),
    ...mapActions(["fetchSalons", "deleteSalon"]),
    salonDelete(salon) {
      this.promptText = "Supprimer le salon " + salon.nom_salon + " ?";
      this.promptCallback = () => {
        this.deleteSalon(salon);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
    reservationClass(reservationValue) {
      return reservationValue ? "reservation bggreen" : "reservation bgred";
    },
    isRapportMode() {
      var index = this.headers.findIndex((h) => h.value == "actions");
      return !this.headers[index].show;
    },
  },
  computed: {
    salons() {
      return this.getSalons();
    },
    _headers() {
      return this.headers.filter((h) => h.show);
    },
  },
  filters: {
    reservationLabel: function (value) {
      return value ? "Oui" : "Non";
    },
  },
  props: ["projet_id", "rapport_view", "headers"],
  created() {
    this.fetchSalons(this.$props.projet_id).then(() => {
      this.dataFetched = true;
    });
  },
};
</script>

<style scoped>
.reservation {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bgred {
  background-color: red;
}

.bggreen {
  background-color: lightgreen;
}
</style>