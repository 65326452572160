import axios from "axios"
import { salonsUrl, salonUrl } from "../urls"

const state = {
    salons: null
}

const getters = {
    getSalons: (state) => {
        return state.salons
    },
    getSalon: (state) => (salon_id) => {
        return state.salons.find(s => s.id == salon_id)
    }
}

const actions = {
    async fetchSalons({ commit }, projet_id) {
        var url = salonsUrl.replace("{id}", projet_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setSalons", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null
        });
        return reponse;
    },
    addSalon({ commit }, salon) {
        var url = salonsUrl.replace("{id}", salon.projet)
        axios.post(url, salon).then((response) => {
            if (response.status == 201) {
                var salon = response.data
                commit("addSalon", salon)
            }
        }).catch(error => {
            console.log(error)
        });
    },

    //eslint-disable-next-line no-unused-vars
    updateSalon: function({ commit }, salon) {
        var url = salonUrl.replace("{id}", salon.id)
        axios.put(url, salon).then((response) => {
            if (response.status == 200) {
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },
    deleteSalon: function({ commit }, salon) {
        var url = salonUrl.replace("{id}", salon.id)
        axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteSalon", salon);
            }
        });
    },
}

const mutations = {
    setSalons: (state, salons) => { state.salons = salons },
    addSalon: (state, salon) => {
        state.salons.push(salon);
    },
    updateSalon: (state, salon) => {
        var salonIndex = state.salons.findIndex(s => s.id == salon.id)
        state.salons[salonIndex] = salon
    },
    deleteSalon: (state, salon) => {
        var salonIndex = state.salons.findIndex(s => s.id == salon.id);
        state.salons.splice(salonIndex, 1);
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}