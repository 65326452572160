<template>
  <div>
    <h1>Rapport</h1>
    <br/>
    <div>
      <p>
            Télécharger(format Pdf):
            <v-btn @click="pdfBtnClick" icon x-large color="red"
              ><v-icon>mdi-file-pdf-box</v-icon></v-btn
            >
          </p>
    </div>
    <br />
    <div class="col-md-12" style="margin:0 auto">
      <div>
        <h2>Ensemble du projet</h2>
        <div class="piecharts">
          <div>
            <apexchart
              type="donut"
              width="375"
              :options="dataEcheanciersProjet.options"
              :series="dataEcheanciersProjet.series"
            ></apexchart>
          </div>
          <div>
            <apexchart
              type="donut"
              width="375"
              :options="dataAvancementProjet.options"
              :series="dataAvancementProjet.series"
            ></apexchart>
          </div>
        </div>
        <RapportCommentairesComponent
          :commentaires="firstSectionCommentaires"
          type_rapport="interne"
          :projet_id="projet_id"
          :section="sections[0]"
        ></RapportCommentairesComponent>
      </div>
      <div>
        <br />
        <h2>Salons et évènements</h2>
        <div>
          <SalonsTableComponent
            :projet_id="projet_id"
            :headers="salons_headers"
          ></SalonsTableComponent>
        </div>
        <br />
        <RapportCommentairesComponent
          :commentaires="secondSectionCommentaires"
          type_rapport="interne"
          :projet_id="projet_id"
          :section="sections[1]"
        ></RapportCommentairesComponent>
      </div>
      <br />
      <div>
        <div class="histograms">
          <div
            v-for="(objectif, index) in objectifs"
            :key="objectif.id"
            class="chart"
          >
            <h2>{{ objectif.nom_objectif }}</h2>
            <apexchart
              width="600px"
              :options="stackedBarDataEcheanciersPerObjectif[index].options"
              :series="stackedBarDataEcheanciersPerObjectif[index].series"
            ></apexchart>
            <br />
            <apexchart
              width="600px"
              :options="stackedBarDataAvancementPerObjectif[index].options"
              :series="stackedBarDataAvancementPerObjectif[index].series"
            ></apexchart>
          </div>
        </div>
        <RapportCommentairesComponent
          :commentaires="thirdSectionCommentaires"
          type_rapport="interne"
          :projet_id="projet_id"
          :section="sections[2]"
        ></RapportCommentairesComponent>
      </div>
    </div>
    <DialogLoaderComponent ref="loader" v-bind:dialog="loader"></DialogLoaderComponent>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SalonsTableComponent from "../Salons/SalonsTableComponent.vue";
import RapportCommentairesComponent from "./RapportCommentairesComponents.vue";
import DialogLoaderComponent from "../Dialog/DialogLoaderComponent.vue";
export default {
  name: "RapportInterne",
  components: { SalonsTableComponent, RapportCommentairesComponent,DialogLoaderComponent},
  data:()=>({
    loader:false
  }),
  props: [
    "projet_id",
    "salons_headers",
    "sections",
    "commentaires",
    "objectifs",
    "dataEcheanciersProjet",
    "dataAvancementProjet",
    "stackedBarDataAvancementPerObjectif",
    "stackedBarDataEcheanciersPerObjectif",
  ],
  methods:
  {
    ...mapActions(["fetchRapportInternePdf"]),
    pdfBtnClick()
    {
      this.loader=true
      this.fetchRapportInternePdf(this.projet_id).then(response=>{
        if(response!=null)
        {
          this.loader=false
          window.open(response)
        }
      })
    }
  },
  computed: {
    firstSectionCommentaires() {
      return this.commentaires
        .reduce((accumulator, c) => {
          var section = this.sections[0];
          if (c.section == section) {
            if (!(section in accumulator)) {
              accumulator[section] = [];
            }
            accumulator.push(c);
          }
          return accumulator;
        }, [])
        .reverse();
    },
    secondSectionCommentaires() {
      return this.commentaires
        .reduce((accumulator, c) => {
          var section = this.sections[1];
          if (c.section == section) {
            if (!(section in accumulator)) {
              accumulator[section] = [];
            }
            accumulator.push(c);
          }
          return accumulator;
        }, [])
        .reverse();
    },
    thirdSectionCommentaires() {
      return this.commentaires
        .reduce((accumulator, c) => {
          var section = this.sections[2];
          if (c.section == section) {
            if (!(section in accumulator)) {
              accumulator[section] = [];
            }
            accumulator.push(c);
          }
          return accumulator;
        }, [])
        .reverse();
    },
  },
};
</script>
