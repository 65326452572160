<template>
  <div>
    <NavMenuComponent :projet_id="projet.id"></NavMenuComponent>
    <div>
      <h1>Plan d'action: {{ projet.nom_projet }}</h1>
      <div v-if="dataFetched">
        <div>
          <p>
            Exporter(format Excel):
            <v-btn @click="excelBtnClick" icon color="green" x-large>
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </p>
          <a v-show="false" download="feuille.xlsx" ref="excelDownloadLink"></a>
        </div>
        <draggable v-model="objectifs" @change="ordreChanged">
          <div v-for="objectif in objectifs" :key="objectif.id">
            <ObjectifsComponent v-bind:objectif="objectif"></ObjectifsComponent>
          </div>
        </draggable>
        <br />
        <div class="print-hide">
          <ObjectifFormComponent btn_text="Ajouter Objectif" titre="Ajouter objectif" :edit="false"
            :projet_id="projet.id"></ObjectifFormComponent>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar" >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import draggable from 'vuedraggable';
import ObjectifsComponent from "./ObjectifsComponent";
import ObjectifFormComponent from "./ObjectifFormComponent";
import NavMenuComponent from "../NavMenuComponent";

export default {
  name: "PlanActionComponent",
  components: { ObjectifsComponent, ObjectifFormComponent, NavMenuComponent, draggable },
  props: ["projet"],
  data: () => ({
    objectifs: null,
    activites: null,
    taches: null,
    text: "",snackbar:false,
  }),
  methods: {
    ...mapGetters(["getProjet", "isAdmin"]),
    ...mapActions(["selectProjet", "updateOrdreObjectifs", "fetchProjet", "fetchObjectifsProjet", "fetchActivitesProjet", "fetchTachesProjet", "fetchPlanActionExcel"]),
    excelBtnClick() {
      this.fetchPlanActionExcel(this.projet.id).then((result) => {
        if (result != null) {
          var link = this.$refs.excelDownloadLink;
          link.href = result;
          link.click();
        }
      });
    },
    ordreChanged() {
      if (this.isAdmin()) {
        let ordonnancementObjectifs = this.objectifs.reduce(
          (accumulator, p, index) => {
            accumulator.push({ "id": p.id, "ordre": index + 1 });
            return accumulator;
          },
          []
        );
        this.updateOrdreObjectifs(ordonnancementObjectifs)
      }
      else {
        this.nonAdminOrderChangeFeedback()
      }
    },
    nonAdminOrderChangeFeedback() {
      this.text = "Ce changement ne sera pas enregistré car il est reservé aux administrateurs"
      this.snackbar = true;
    },

  },
  computed: {
    dataFetched() {
      return this.objectifs != null && this.activites != null && this.taches != null
    },
    objectifsSorted() {
      var objectifsCopy = this.objectifs.map((o) => {
        return o;
      });
      return objectifsCopy.sort((a, b) => {
        if (a.ordre == b.ordre) {
          return 0;
        } else if (a.ordre < b.ordre) {
          return -1;
        } else {
          return 1;
        }
      });
    },
  },
  created: function () {
    var projet_id = this.$props.projet.id;
    this.fetchObjectifsProjet(projet_id).then((o) => {
      this.objectifs = o;
    });
    this.fetchActivitesProjet(projet_id).then((a) => {
      this.activites = a;
    });
    this.fetchTachesProjet(projet_id).then((t) => {
      this.taches = t;
    });

  },
};
</script>