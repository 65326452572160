<template>
  <div class="ganttTable">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="activite_nom"></th>
            <th class="tache_nom"></th>
            <th class="echeancier">Echéancier</th>
            <th class="header" v-for="(header, index) in headers" :key="index">
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <tbody v-for="activite in activites" v-bind:key="activite.id">
          <tr class="activite_row">
            <td class="activite_nom">
              {{ activite.nom_activite }}
            </td>
            <td class="tache_nom"></td>
            <td class="echeancier">
              {{ activite.date_fin }}
            </td>
            <!-- <td v-for="(header, index) in headers" :key="index" ref="activiteCell" :class="setCellGradient(index,activite)"></td> -->
            <td v-for="(header, index) in headers" :key="index" v-bind:class="getCellClass(header.date, activite)"></td>
          </tr>
          <tr v-for="tache in tachesByActivite[activite.id]" :key="tache.id" class="tache_row">
            <td class="activite_nom"></td>
            <td class="tache_nom">{{ tache.nom_tache }}</td>
            <td class="echeancier">{{ tache.date_fin }}</td>
            <td v-for="(header, index) in headers" :key="index" v-bind:class="getCellClass(header.date, tache)"></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "GanttTableComponent",
  props: ["activites", "taches", "date_debut"],
  data: () => ({
    numberOfMonths: 20,
  }),
  computed: {
    headers() {
      var moment = this.$moment;
      moment.locale("fr");
      var headers = [];
      var date = this.$props.date_debut == null ? this.debutGantt() : moment(this.$props.date_debut, "YYYY-MM-DD");
      var momentDate = moment();
      momentDate.day(date.day())
      momentDate.month(date.month())
      momentDate.year(date.year())
      momentDate=momentDate.startOf("month");
      var derniereEcheance = this.derniereEcheance();
      do {
        let name = momentDate.format("MMM YYYY");
        let dateString = momentDate.format("YYYY-MM-DD");
        let entry = { name: name, date: dateString };
        headers.push(entry);
        momentDate.add(1, "M");
      }
      while (momentDate.isSameOrBefore(derniereEcheance))
      return headers;
    },
    tachesByActivite() {
      return this.taches.reduce((accumulator, tache) => {
        let activite_id = tache.activite
        if (Object.keys(accumulator).includes(activite_id.toString()))
          accumulator[activite_id].push(tache)
        else
          accumulator[activite_id] = [tache]
        return accumulator
      }, {})
    }
  },
  methods: {
    getCellClass(date, element) {
      var moment = this.$moment;
      var headerDate = moment(date);
      var dateDebutTache = moment(element.date_debut);
      var dateFinTache = moment(element.date_fin);
      if (
        headerDate.isSameOrAfter(dateDebutTache, "month") &&
        headerDate.isSameOrBefore(dateFinTache, "month")
      ) {
        var result
        let daysInMonth = headerDate.daysInMonth();
        let doElementStartsInMonth = dateDebutTache.month() == headerDate.month() && dateDebutTache.year()==headerDate.year()
        let doElementEndsInMonth = dateFinTache.month() == headerDate.month() && dateFinTache.year()==headerDate.year()

        //Cellule active, l'element couvre tout le mois
        if ((!doElementStartsInMonth && !doElementEndsInMonth)) {
          result = "active";
          return result
        }

        //l'element comment et se termine dans le meme mois
        if (doElementStartsInMonth && doElementEndsInMonth) {
          var percentageDebut;
          var percentageFin;
          let dateDebutJour = dateDebutTache.date()
          var nbJoursManquant = dateDebutJour == 1 ? 0 : (dateDebutJour - 1)

          percentageDebut =
            (parseInt(nbJoursManquant) / parseInt(daysInMonth)) * 100;
          percentageDebut = parseInt(Number((percentageDebut / 25).toFixed(1)))
          percentageDebut = percentageDebut * 25;

          nbJoursManquant = daysInMonth - dateFinTache.date()
          percentageFin =
            (nbJoursManquant / parseInt(daysInMonth)) * 100;
          //Arrondissment de la valeur a décimale la plus proche
          percentageFin = parseInt(Number((percentageFin / 25).toFixed(1)))
          percentageFin = percentageFin * 25;
          percentageFin = 100 - percentageFin

          result = "partialy-active-same-month "
          result += "s-" + percentageDebut + " "
          result += "e-" + percentageFin
        }

        else {
          //Commence dans ce mois uniquement 
          if (doElementStartsInMonth) {
            let dateDebutJour = dateDebutTache.date()
            let nbJoursTotal = dateDebutJour == 1 ? daysInMonth : daysInMonth - dateDebutJour
            percentageDebut =
              (parseInt(nbJoursTotal) / parseInt(daysInMonth)) * 100;
            //Arrondissment de la valeur a décimale la plus proche
            percentageDebut = parseInt(Number((percentageDebut / 25).toFixed(1)))
            percentageDebut = percentageDebut * 25;

            result = "partialy-active ";
            result += "p-" + percentageDebut;
          }

          //Se termine dans ce mois uniquement 
          else if (doElementEndsInMonth) {
            percentageFin =
              (parseInt(dateFinTache.date()) / parseInt(daysInMonth)) * 100;
            //Arrondissment de la valeur a décimale la plus proche
            percentageFin = parseInt(Number((percentageFin / 25).toFixed(1)))
            percentageFin = percentageFin * 25;

            result = "partialy-active-reverse ";
            result += "p-" + percentageFin;
          }
        }

        return result;
      } else return "inactive";
    },

    setCellGradient(index, activite) {
      let activitesCells = this.$refs["activiteCell"][index]
      let ctx = activitesCells.getContext("2d")
      var grad = ctx.createLinearGradient(0, 0, 190, 0);
      grad.addColorStop(1, "white");
      ctx.fillStyle = grad;
      ctx.fillRect(0, 0, 200, 0);
      console.log(activite)
    },
    derniereEcheance() {
      var moment = this.$moment;
      //var dateDebut = moment(this.date_debut);
      var dateDebut = this.debutGantt();
      var derniereEcheance = dateDebut
      this.activites.map((a) => {
        let dateFin = moment(a.date_fin)
        if (dateFin.isSameOrAfter(derniereEcheance))
          derniereEcheance = dateFin
      });
      this.taches.map((t) => {
        let dateFin = moment(t.date_fin)
        if (dateFin.isSameOrAfter(derniereEcheance))
          derniereEcheance = dateFin
      });
      derniereEcheance = derniereEcheance.endOf('month')
      var momentDate = moment()
      momentDate.day(derniereEcheance.day())
      momentDate.month(derniereEcheance.month())
      momentDate.year(derniereEcheance.year())
      return momentDate
    },
    debutGantt() {
      var moment = this.$moment;
      var date = moment();
      var premiereEcheance = date
      this.activites.map((a) => {
        let dateDebut = moment(a.date_fin)
        if (dateDebut.isSameOrBefore(premiereEcheance))
          premiereEcheance = dateDebut
      });
      this.taches.map((t) => {
        let dateDebut = moment(t.date_fin)
        if (dateDebut.isSameOrBefore(premiereEcheance))
          premiereEcheance = dateDebut
      });
      premiereEcheance = premiereEcheance.startOf('month')
      var momentDate = moment()
      momentDate.day(premiereEcheance.day())
      momentDate.month(premiereEcheance.month())
      momentDate.year(premiereEcheance.year())
      return momentDate
    },
  },
};
</script>

<style>
.ganttTable>.v-data-table {
  width: max-content;
}

.active {
  background-color: #7191c3;
}

td.active,
td.inactive,
td.partialy-active,
td.partialy-active-reverse,
td.partialy-active-same-month {
  border-right: thin solid rgba(0, 0, 0, .12) !important;
}



.partialy-active.p-100 {
  background: linear-gradient(to right, #7191c3 0%, #7191c3 100%);
}

.partialy-active.p-25 {
  background: linear-gradient(to right, #ffffff 0%, #ffffff 74%, #7191c3 75%, #7191c3 100%)
}

.partialy-active.p-50 {
  background: linear-gradient(to right, #ffffff 0%, #ffffff 49%, #7191c3 50%, #7191c3 100%);
}

.partialy-active.p-75 {
  background: linear-gradient(to right, #ffffff 0%, #ffffff 24%, #7191c3 25%, #7191c3 100%);
}

.partialy-active.p-0 {
  background: linear-gradient(to right, #ffffff 0%, #ffffff 100%);
}


.partialy-active-reverse.p-0 {
  background: linear-gradient(to right, #ffffff 0%, #ffffff 100%);
}

.partialy-active-reverse.p-25 {
  background: linear-gradient(to right, #7191c3 0%, #7191c3 24%, #ffffff 25%);
}

.partialy-active-reverse.p-50 {
  background: linear-gradient(to right, #7191c3 0%, #7191c3 49%, #ffffff 50%);
}

.partialy-active-reverse.p-75 {
  background: linear-gradient(to right, #7191c3 0%, #7191c3 74%, #ffffff 75%);
}

.partialy-active-reverse.p-100 {
  background: linear-gradient(to right, #7191c3 0%, #7191c3 100%);
}



.partialy-active-same-month.s-0.e-25 {
  background: linear-gradient(to right, #7191c3 0%, #7191c3 24%, #ffffff 25%, #ffffff 100%);
}

.partialy-active-same-month.s-0.e-50 {
  background: linear-gradient(to right, #7191c3 0%, #7191c3 49%, #ffffff 50%, #fff 99%);
}

.partialy-active-same-month.s-0.e-75 {
  background: linear-gradient(to right, #7191c3 0%, #7191c3 74%, #ffffff 75%, #ffffff 100%);
}

.partialy-active-same-month.s-0.e-100 {
  background: linear-gradient(to right, #7191c3 0%, #7191c3 100%);
}

.partialy-active-same-month.s-25.e-50 {
  background: linear-gradient(to right, #ffffff 0%, #ffff 24%, #7191c3 25%, #7191c3 49%);
}

.partialy-active-same-month.s-25.e-75 {
  background: linear-gradient(to right, #ffffff 0%, #ffff 24%, #7191c3 25%, #7191c3 74%, #ffff 75%);
}

.partialy-active-same-month.s-25.e-100 {
  background: linear-gradient(to right, #ffffff 0%, #ffff 24%, #7191c3 25%, #7191c3 100%);
}

.partialy-active-same-month.s-50.e-75 {
  background: linear-gradient(to right, #ffffff 0%, #ffff 49%, #7191c3 50%, #7191c3 74%, #ffff 75%);
}

.partialy-active-same-month.s-50.e-100 {
  background: linear-gradient(to right, #ffffff 0%, #ffff 49%, #7191c3 50%, #7191c3 100%);
}

.partialy-active-same-month.s-75.e-100 {
  background: linear-gradient(to right, #ffffff 0%, #ffff 74%, #7191c3 75%, #7191c3 100%);
}


.activite_nom,
.tache_nom {
  width: 250px !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.echeancier {
  width: 200px !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.header {
  width: 150px !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
</style>