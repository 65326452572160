<template>
  <div id="navMenu">
    <v-row>
      <v-col
        ><router-link :to="planActionLink"
          >Plan d'action stratégique</router-link
        ></v-col
      >
      <v-col
        ><router-link :to="ganttLink">Diagramme de Gantt</router-link></v-col
      >
      <v-col><router-link :to="salonsLink">Salons</router-link></v-col>
      <v-col><router-link :to="rapportsLink">Rapports</router-link></v-col>
      <v-col v-if="isAdmin()"
        ><router-link :to="rappelsLink">Rappels</router-link></v-col
      >
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NavMenuComponent",
  props: ["projet_id"],
  data: () => ({
    planActionLink: "",
    ganttLink: "",
    salonsLink: "",
    rapportsLink: "",
    rappelsLink: "",
  }),
  methods: {
    ...mapGetters(["isAdmin"]),
  },
  created() {
    this.planActionLink = "/planaction/" + this.$props.projet_id;
    this.ganttLink = "/gantt/" + this.$props.projet_id;
    this.salonsLink = "/salons/" + this.$props.projet_id;
    this.rapportsLink = "/rapports/" + this.$props.projet_id;
    this.rappelsLink = "/rappels/" + this.$props.projet_id;
  },
};
</script>

<style scoped>
#navMenu {
  width: 100%;
  margin: 20px;
  background-color: ghostwhite;
  box-shadow: 10px black;
}

@media print
{
  #navMenu
  {
    display: none;
  }
}
</style>