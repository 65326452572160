import axios from "axios"
import { projetsUrl,projetOrdreUrl, projetUrl, projetUsersUrl, projetUnassignUrl, userProjetsUrl, assignationsUrl, planActionExcelUrl, rapportInternePdfUrl, rapportExternePdfUrl } from "../urls"

const state = {
    projets: null,
    projet: null
}

const getters = {
    getProjets: (state) => {
        return state.projets
    },
    getProjet: (state) => {
        return state.projet;
    },
    getProjetFromProjets: (state) => (projet_id) => {
        return state.projets.find(p => p.id == projet_id);
    },
}

const actions = {
    async fetchProjets({ commit }) {
        const reponse = await axios.get(projetsUrl).then((reponse) => {
            if (reponse.status == 200) {
                commit("setProjets", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null
        });
        return reponse;
    },
    async fetchUserProjets({ commit }, user_id) {
        var url = userProjetsUrl.replace("{id}", user_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setProjets", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null
        });
        return reponse;
    },
    async fetchProjet({ commit }, projet_id) {
        var url = projetUrl.replace("{id}", projet_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setProjet", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null
        });
        return reponse;
    },

    //eslint-disable-next-line no-unused-vars
    async fetchProjetUsers({ commit }, projet_id) {
        var url = projetUsersUrl.replace("{id}", projet_id);
        return await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null
        });
    },


    //eslint-disable-next-line no-unused-vars
    async fetchPlanActionExcel({ commit }, projet_id) {
        var url = planActionExcelUrl.replace("{id}", projet_id);
        const reponse = await axios({ url: url, method: "GET", responseType: "blob" }).then((reponse) => {
            if (reponse.status == 200) {
                let blob = new Blob([reponse.data], { type: "application/xlsx" });
                const url = window.URL.createObjectURL(blob);
                return url
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    //eslint-disable-next-line no-unused-vars
    async fetchRapportInternePdf({ commit }, projet_id) {
        var url = rapportInternePdfUrl.replace("{id}", projet_id);
        const reponse = await axios({ url: url, method: "GET", responseType: "blob" }).then((reponse) => {
            if (reponse.status == 200) {
                let blob = new Blob([reponse.data], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                return url
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    //eslint-disable-next-line no-unused-vars
    async fetchRapportExternePdf({ commit }, projet_id) {
        var url = rapportExternePdfUrl.replace("{id}", projet_id);
        const reponse = await axios({ url: url, method: "GET", responseType: "blob" }).then((reponse) => {
            if (reponse.status == 200) {
                let blob = new Blob([reponse.data], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                return url
            }
            return null
        }).catch((error) => {
            console.log(error.data)
            return null
        });
        return reponse;
    },

    async addProjet({ commit }, projet) {
        var formData = new FormData()
        formData.append("nom_projet", projet.nom_projet)
        formData.append("description", projet.description)
        if (projet.logo_projet != null)
            formData.append("logo_projet", projet.logo_projet)
        return await axios.post(projetsUrl, formData, { headers: { "Content-type": "multipart/form-data" } }).then((response) => {
            if (response.status == 201) {
                var projet = response.data
                commit("addProjet", projet)
                return projet
            }
            return null
        }).catch(error => {
            console.log(error)
            return null
        });
    },

    //eslint-disable-next-line no-unused-vars
    async addAssignations({ commit }, assignations) {
        var _assignations = assignations.map((a) => { return { "projet": a.projet, "utilisateur": a.utilisateur } })
        return await axios.post(assignationsUrl, _assignations).then((response) => {
            if (response.status == 201) {
                commit("addProjetUsers", response.data)
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },

    //eslint-disable-next-line no-unused-vars
    async removeAssignations({ commit }, data) {
        var url = projetUnassignUrl.replace("{id}", data.projet_id);
        return await axios.post(url, data).then((response) => {
            if (response.status == 200) {
                commit("removeProjetUsers", data)
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },

    //eslint-disable-next-line no-unused-vars
    async updateOrdreProjets({ commit }, data) {
        return await axios.post(projetOrdreUrl, data).then((response) => {
            if (response.status == 200) {
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },

    async updateProjet({ commit }, projet) {
        var formData = new FormData()
        formData.append("id", projet.id)
        formData.append("nom_projet", projet.nom_projet)
        formData.append("description", projet.description)
        if (projet.logo_projet != null)
            formData.append("logo_projet", projet.logo_projet)
        var url = projetUrl.replace("{id}", projet.id);
        return await axios.put(url, formData, { headers: { "Content-type": "multipart/form-data" } }).then((response) => {
            if (response.status == 200) {
                commit("updateProjet", response.data)
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },

    commitAddProjet({ commit }, projet) {
        commit("addProjet", projet)
    },

    setProjets({ commit }, projets) {
        commit("setProjets", projets)
    },

    deleteProjet({ commit }, projet) {
        var url = projetUrl.replace("{id}", projet.id);
        axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteProjet", projet);
            }
        });
    }
}
const mutations = {
    setProjets: (state, projets) => { state.projets = projets },
    setProjet: (state, projet) => { state.projet = projet },
    addProjet: (state, projet) => {
        state.projets.push(projet);
    },
    removeProjetUsers(state, data) {
        var projetIndex = state.projets.findIndex(p => p.id == data.projet_id)
        data.utilisateurs.map((u) => {
            var index = state.projets[projetIndex].utilisateurs.findIndex(utilisateur => utilisateur.id == u)
            state.projets[projetIndex].utilisateurs.splice(index, 1)
        })
    },
    addProjetUsers(state, data) {
        let projet_id = data.projet_id;
        let projetIndex = state.projets.findIndex(p => p.id == projet_id)
        state.projets[projetIndex].utilisateurs = data.utilisateurs
    },
    updateProjet: (state, updatedProjet) => {
        var projetIndex = state.projets.findIndex(p => p.id == updatedProjet.id)
        var projet = state.projets[projetIndex]
        projet.nom_projet = updatedProjet.nom_projet
        projet.description = updatedProjet.description
        projet.logo_projet = updatedProjet.logo_projet
    },
    deleteProjet: (state, projet) => {
        var projetIndex = state.projets.findIndex(p => p.id == projet.id);
        state.projets.splice(projetIndex, 1);
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}