<template>
  <div>
    <draggable v-model="activitesModel" tag="div" @change="activitesOrdreChanged">
      <v-simple-table v-for="(activite) in activitesModel" v-bind:key="activite.id">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Date début</th>
            <th>Date fin</th>
            <th>État échéancier</th>
            <th>Avancement</th>
            <th>RESPONSABLE</th>
            <th>AVEC L'APPUI DE</th>
            <th>Commentaires</th>
            <th class="print-hide">Controles</th>
          </tr>
        </thead>
        <draggable v-model="tachesByActiviteModel[activite.id]" tag="tbody" @change="tachesOrdreChanged(activite.id)">
          <tr class="activite_row" slot="header">
            <td class="activite_nom">
              {{ activite.nom_activite }}
            </td>
            <td></td>
            <td>{{ activite.date_debut }}</td>
            <td>{{ activite.date_fin }}</td>
            <td v-bind:class="getClassEtatEcheancier(activite.etat_echeancier)">
              {{ activite.etat_echeancier }}
            </td>
            <td v-bind:class="getClassAvancement(activite.avancement)">
              {{ activite.avancement }}
            </td>
            <td>{{ activite.responsable }}</td>
            <td>{{ activite.appui }}</td>
            <td>{{ activite.commentaires }}</td>
            <td class="controls print-hide">
              <v-row>
                <ActiviteFormComponent titre="Modifier activité" btn_text="Modifier" :objectif_id="objectif_id"
                  :activite_id="activite.id" :edit="true"></ActiviteFormComponent>
                <RappelsFormComponent v-on:rappelAdded="rappelFeedback" v-if="isAdmin()"
                  :rappel_actif="activite.rappel_actif" btn_text="Nouveau rappel"
                  :titre="'Rappel pour l\'activité ' + activite.nom_activite" :projet_id="projet_id"
                  :responsable_email="activite.responsable" objetRappel="activite" :idObjet="activite.id">
                </RappelsFormComponent>
                <ActiviteToTacheFormComponent :activite="activite">
                </ActiviteToTacheFormComponent>
                <v-btn v-if="isAdmin()" icon small color="red" @click="activiteDelete(activite)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </td>
          </tr>
          <tr v-for="tache in tachesByActiviteModel[activite.id]" :key="tache.id" class="tache_row">
            <td></td>
            <td class="tache_nom">{{ tache.nom_tache }}</td>
            <td>{{ tache.date_debut }}</td>
            <td>{{ tache.date_fin }}</td>
            <td v-bind:class="getClassEtatEcheancier(tache.etat_echeancier)">
              {{ tache.etat_echeancier }}
            </td>
            <td v-bind:class="getClassAvancement(tache.avancement)">
              {{ tache.avancement }}
            </td>
            <td>{{ tache.responsable }}</td>
            <td>{{ tache.appui }}</td>
            <td>{{ tache.commentaires }}</td>
            <td class="controls print-hide">
              <TacheFormComponent btn_text="Modifier" titre="Modifier tache" :edit="true" :tache_id="tache.id"
                :activite_id="activite.id" :objectif_id="objectif_id"></TacheFormComponent>
              <RappelsFormComponent v-if="isAdmin()" v-on:rappelAdded="rappelFeedback"
                :rappel_actif="tache.rappel_actif" btn_text="Nouveau rappel"
                :titre="'Rappel pour la tache ' + tache.nom_tache" :projet_id="projet_id"
                :responsable_email="tache.responsable" objetRappel="tache" :idObjet="tache.id"></RappelsFormComponent>
              <TacheToActiviteFormComponent :tache="tache" :objectif_id="objectif_id">
              </TacheToActiviteFormComponent>
              <v-btn v-if="isAdmin()" icon small color="red" @click="tacheDelete(tache)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr class="print-hide" slot="footer">
            <td colspan="11">
              <TacheFormComponent btn_text="Ajouter tache" titre="Ajouter tache" :edit="false"
                :activite_id="activite.id" :objectif_id="objectif_id"></TacheFormComponent>
            </td>
          </tr>
        </draggable>
      </v-simple-table>
      <br />
      <div slot="footer">
        <ConfirmationPromptComponent ref="confirmationPrompt" :titre="promptTitle" v-bind:text="promptText"
          v-bind:callback="promptCallback"></ConfirmationPromptComponent>
        <v-snackbar v-model="snackbar" >
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Fermer
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </draggable>
    <ActiviteFormComponent titre="Ajouter Activité" btn_text="Ajouter Activité" :edit="false"
      :objectif_id="objectif_id"></ActiviteFormComponent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import ActiviteFormComponent from "./ActivitéFormComponent";
import TacheToActiviteFormComponent from "./TacheToActiviteFormComponent";
import ActiviteToTacheFormComponent from "./ActiviteToTacheFormComponent";
import TacheFormComponent from "./TacheFormComponent";
import RappelsFormComponent from "./RappelsFormComponent.vue";
import ConfirmationPromptComponent from "../Dialog/ConfirmationPromptComponent";
export default {
  name: "ActiviteComponent",
  props: ["objectif_id", "projet_id"],
  data: () => ({
    promptCallback: null,
    activitesModel: null,
    tachesByActiviteModel: null,
    promptText: "",
    promptTitle: "",
    snackbar: false,
    timeout: 2000,
    text: "test",
  }),
  components: {
    ActiviteFormComponent,
    TacheToActiviteFormComponent,
    ActiviteToTacheFormComponent,
    TacheFormComponent,
    ConfirmationPromptComponent,
    RappelsFormComponent,
    draggable,
  },
  watch: {
    activites(newValue) {
      this.activitesModel = newValue.sort(this.sortItems)
    },
    tachesByActivite(newValue) {
      this.tachesByActiviteModel = {};
      Object.entries(newValue).forEach((entry) => {
        let [key, value] = entry
        this.tachesByActiviteModel[key] = value.sort(this.sortItems)
      })
    }
  },
  computed: {
    activites() {
      return this.getActivites().filter((a) => a.objectif == this.objectif_id)
    },
    tachesByActivite() {
      return this.getTaches().reduce((accumulator, tache) => {
        let activite_id = tache.activite
        if (Object.keys(accumulator).includes(activite_id.toString()))
          accumulator[activite_id].push(tache)
        else
          accumulator[activite_id] = [tache]
        return accumulator
      }, {})
    }
  },
  methods: {
    ...mapActions(["deleteTache", "deleteActivite", "convertTacheToActivite", "updateOrdreActivites", "updateOrdreTaches"]),

    ...mapGetters(["isAdmin", "getActivites", "getTaches"]),

    activitesOrdreChanged() {
      if (this.isAdmin()) {
        let ordonnancementActivites = this.activitesModel.reduce(
          (accumulator, a, index) => {
            accumulator.push({ "id": a.id, "ordre": index + 1 });
            a.ordre = index + 1
            return accumulator;
          },
          []
        );
        this.updateOrdreActivites(ordonnancementActivites)
      }
      else {
        this.nonAdminOrderChangeFeedback()
      }
    },

    tachesOrdreChanged(activite_id) {
      if (this.isAdmin()) {
        let taches = this.tachesByActivite[activite_id];
        let ordonnancementTaches = taches.reduce(
          (accumulator, t, index) => {
            t.ordre = index + 1
            accumulator.push({ "id": t.id, "ordre": index + 1 });
            return accumulator;
          },
          []
        );
        this.updateOrdreTaches(ordonnancementTaches)
      }
      else {
        this.nonAdminOrderChangeFeedback()
      }
    },

    sortItems(a, b) {
      if (a.ordre > b.ordre)
        return 1
      else if (a.ordre < b.ordre)
        return -1
      else return 0
    },

    convertTacheToActiviteBtnClick(tache) {
      this.promptTitle = "Confirmer conversion"
      this.promptText = "Convertir la tâche " + tache.nom_tache + " en activité ?";
      this.promptCallback = () => {
        tache.objectif = this.$props.objectif_id;
        this.convertTacheToActivite(tache);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },

    tacheDelete(tache) {
      this.promptTitle = "Confirmer suppression"
      this.promptText = "Supprimer la tâche " + tache.nom_tache + " ?";
      this.promptCallback = () => {
        tache.objectif = this.$props.objectif_id;
        this.deleteTache(tache);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },

    activiteDelete(activite) {
      this.promptTitle = "Confirmer suppression"
      this.promptText = "Supprimer l'activité " + activite.nom_activite + " ?";
      this.promptCallback = () => {
        activite.objectif = this.$props.objectif_id;
        this.deleteActivite(activite);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },

    nonAdminOrderChangeFeedback() {
      this.text = "Ce changement ne sera pas enregistré car il est reservé aux administrateurs"
      this.snackbar = true;
    },

    rappelFeedback(result) {
      if (result) {
        this.text = "Votre rappel a bien été ajouté";
      } else {
        this.text = "Erreur lors de l'ajout de votre rappel";
      }
      this.snackbar = true;
    },
    getClassEtatEcheancier(etat_echeancier) {
      var result = "";
      switch (etat_echeancier) {
        case "Retard": {
          result = "bgred";
          break;
        }
        case "Respecté": {
          result = "bggrey";
          break;
        }
        case "Devancé": {
          result = "bggreen";
          break;
        }
        default: {
          result = "";
          break;
        }
      }
      return result;
    },
    getClassAvancement(avancement) {
      var result = "";
      switch (avancement) {
        case "Non débuté": {
          result = "bgred";
          break;
        }
        case "En cours": {
          result = "bgorange";
          break;
        }
        case "Terminé": {
          result = "bggreen";
          break;
        }
        default: {
          result = "";
          break;
        }
      }
      return result;
    },
  },
  created() {
    this.activitesModel = this.activites
    this.tachesByActiviteModel = this.tachesByActivite
  }
};
</script>

<style scoped>
table thead tr th {
  text-align: center;
}

.bgred {
  background-color: red;
}

.bggrey {
  background-color: lightgrey;
}

.bggreen {
  background-color: lightgreen;
}

.bgorange {
  background-color: orange;
}
</style>
