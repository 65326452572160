<template>
  <div>
    <NavMenuComponent :projet_id="projet_id"></NavMenuComponent>
    <div>
      <h1>Salons commerciaux</h1>
      <SalonsTableComponent
        :projet_id="projet_id"
        :headers="salon_headers"
      ></SalonsTableComponent>
    </div>
  </div>
</template>

<script>
import NavMenuComponent from "../NavMenuComponent";
import SalonsTableComponent from "./SalonsTableComponent.vue";
export default {
  name: "SalonsComponent",
  components: { NavMenuComponent, SalonsTableComponent },
  props: ["projet_id"],
  data: () => ({
    salon_headers: [
      {
        text: "",snackbar:false,
        value: "nom_salon",
        show: true,
      },
      {
        text: "Type de participation",
        value: "type_participation",
        show: true,
      },
      { text: "Réservation effectuée", value: "reservation", show: true },
      { text: "Avancement", value: "avancement", show: true },
      { text: "RESPONSABLE", value: "responsable", show: true },
      { text: "AVEC L'APPUI DE", value: "appui", show: true },
      { text: "Commentaires", value: "commentaires", show: true },
      { text: "Controles", value: "actions", sortable: false, show: true },
    ],
  }),
};
</script>