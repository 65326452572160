<template>
<div><PasswordResetComponent></PasswordResetComponent></div>
</template>

<script>
import PasswordResetComponent from "@/components/Authentication/PasswordResetComponent.vue"
export default {
    name:"PasswordReset",
    components:{PasswordResetComponent}
}
</script>