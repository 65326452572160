<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
      v-if="!isFromRappelsPage"
        :color="rappel_actif == true ? 'blue' : ''"
        icon
        v-bind="attrs"
        v-on="on"
        small
        ><v-icon>mdi-alarm</v-icon></v-btn
      >
      <v-btn
      v-if="isFromRappelsPage"
        v-bind="attrs"
        v-on="on"
        small
        >Ajouter rappel</v-btn
      >
    </template>
    <v-card>
      <v-card-title>{{ titre }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="rappelForm" @submit.prevent="saveForm">
            <div>
              <div v-if="$v.nomRappel.$dirty">
                <div class="error" v-if="!$v.nomRappel.required">
                  Ce champ est requis
                </div>
              </div>
              <v-text-field
                label="Nom rappel"
                v-model="$v.nomRappel.$model"
              ></v-text-field>
            </div>
            <div>
              <v-switch
                :value="false"
                v-model="repetition"
                label="Répéter"
              ></v-switch>
            </div>
            <div v-if="repetition">
              <div>
                <div v-if="$v.periodicite.$dirty">
                  <div class="error" v-if="!$v.periodicite.required">
                    Ce champ est requis
                  </div>
                </div>
                <v-select
                  label="Périodicité"
                  v-model="$v.periodicite.$model"
                  :items="periodiciteItems"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </div>
              <div>
                <div v-if="$v.nbRepetitions.$dirty">
                  <div class="error" v-if="!$v.nbRepetitions.required">
                    Ce champ est requis
                  </div>
                </div>
                <v-text-field
                  type="number"
                  label="Nombre de répétitions"
                  min="1"
                  v-model="$v.nbRepetitions.$model"
                ></v-text-field>
              </div>
            </div>
            <div>
              <div v-if="$v.dateDebut.$dirty">
                <div class="error" v-if="!$v.dateDebut.required">
                  Ce champ est requis
                </div>
              </div>
              <DatePickerComponent
                v-on:dateSaved="saveDateDebut"
                label="Date début"
              ></DatePickerComponent>
            </div>
            <div>
              <div v-if="$v.heure.$dirty">
                <div class="error" v-if="!$v.heure.required">
                  Ce champ est requis
                </div>
              </div>
              <TimePickerComponent
                label="Heure d'envoi"
                v-on:timeSaved="saveTime"
              ></TimePickerComponent>
            </div>
            <div>
              <div v-if="$v.destinataire.$dirty">
                <div class="error" v-if="!$v.destinataire.required">
                  Ce champ est requis
                </div>
                <div class="error" v-if="!$v.destinataire.email">
                  Veuillez entrer une adresse mail valide
                </div>
              </div>
              <v-combobox
                label="Destinataire"
                v-model="$v.destinataire.$model"
                :items="destinatairesList"
              ></v-combobox>
            </div>
            <div v-if="isFromRappelsPage">
              <div>
                <div v-if="$v.typeObjet.$dirty">
                  <div class="error" v-if="!$v.typeObjet.required">
                    Ce champ est requis
                  </div>
                </div>
                <v-select
                  label="Type d'objet du rappel"
                  item-text="label"
                  item-value="value"
                  :items="typeObjetsItems"
                  v-model="$v.typeObjet.$model"
                ></v-select>
              </div>

              <div v-if="typeObjet != null">
                <div v-if="$v.objetId.$dirty">
                  <div class="error" v-if="!$v.objetId.required">
                    Ce champ est requis
                  </div>
                </div>
                <v-select
                  :label="typeObjet"
                  :items="objetsItems[typeObjet]"
                  item-text="nom"
                  item-value="id"
                  v-model="$v.objetId.$model"
                ></v-select>
              </div>
            </div>
            <v-row>
              <v-col><v-btn type="submit">Enregistrer</v-btn></v-col>
              <v-col>
                <v-btn @click="dialog = false">Fermer</v-btn></v-col
              ></v-row
            >
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DatePickerComponent from "../Dialog/DatePickerComponent.vue";
import TimePickerComponent from "../Dialog/TimePickerComponent.vue";
import { required, requiredIf, email } from "vuelidate/lib/validators";
export default {
  name: "RappelsFormComponent",
  props: [
    "btn_text",
    "titre",
    "responsable_email",
    "objetRappel",
    "idObjet",
    "isFromRappelsPage",
    "projet_id",
    "rappel_actif",
  ],
  components: { DatePickerComponent, TimePickerComponent },
  data: () => ({
    nomRappel: "",
    repetition: false,
    periodicite: null,
    nbRepetitions: null,
    dateDebut: "",
    heure: "",
    destinataire: "",
    destinatairesList: [],
    periodiciteItems: [
      { text: "Journalier", value: 1 },
      { text: "Hebdomadaire", value: 7 },
      { text: "Mensuel", value: 30 },
    ],
    typeObjetsItems: [
      { label: "Activité", value: "activite" },
      { label: "Tache", value: "tache" },
    ],
    typeObjet: null,
    objetId: null,
    objetsItems: [],
    dialog: false,
  }),
  validations: {
    nomRappel: {
      required,
    },
    periodicite: {
      required: requiredIf(function () {
        return this.repetition;
      }),
    },
    nbRepetitions: {
      required: requiredIf(function () {
        return this.repetition;
      }),
    },
    dateDebut: {
      required,
    },
    heure: {
      required,
    },
    destinataire: {
      required,
      email,
    },
    typeObjet: {
      required: requiredIf(function () {
        return this.isFromRappelsPage;
      }),
    },
    objetId: {
      required: requiredIf(function () {
        return this.isFromRappelsPage;
      }),
    },
  },
  watch: {
    dialog(value) {
      if (value) this.open();
      else this.close();
    },
  },
  methods: {
    ...mapGetters(["getResponsablesList"]),
    ...mapActions(["addRappel", "fetchObjectifs"]),
    saveForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.repetition == false) {
          this.nbRepetitions = 0;
          this.periodicite = 0;
        }
        var rappel = {
          nom_rappel: this.nomRappel,
          repetition: this.repetition,
          periodicite: this.periodicite,
          nb_repetitions: this.nbRepetitions,
          date_debut: this.dateDebut,
          heure: this.heure,
          date_prochaine_execution: this.dateDebut + " " + this.heure,
          destinataire: this.destinataire,
          projet: this.projet_id,
        };
        if (this.isFromRappelsPage) {
          rappel.objet_rappel = this.typeObjet;
          rappel.id_objet_rappel = this.objetId;
        } else {
          rappel.objet_rappel = this.objetRappel;
          rappel.id_objet_rappel = this.idObjet;
        }

        this.addRappel(rappel).then((r) => {
          if (r) {
            this.$emit("rappelAdded", r);
          }
        });
        this.dialog = false;
      }
    },
    saveDateDebut(date) {
      this.dateDebut = date;
    },
    saveTime(time) {
      this.heure = time;
    },
    open() {
      this.destinataire = this.responsable_email;
      if (this.isFromRappelsPage) {
        this.fetchObjectifs(this.projet_id).then((objectifs) => {
          var objetItems = objectifs.reduce(
            (accumulator, objectif) => {
              var activites = objectif.activites;
              activites.map((activite) => {
                accumulator["Activites"].push({
                  id: activite.id,
                  nom: activite.nom_activite,
                });
                var taches = activite.taches;
                taches.map((tache) => {
                  accumulator["Taches"].push({
                    id: tache.id,
                    nom: tache.nom_tache,
                  });
                });
              });
              return accumulator;
            },
            { Activites: [], Taches: [] }
          );
          this.objetsItems = objetItems;
        });
      }
       this.destinatairesList = this.getResponsablesList();
    },
    close() {
      this.dateDebut = "";
      this.nbRepetitions = null;
      this.periodicite = false;
      this.dataFetched = false;
      this.$refs.rappelForm.reset();
      this.$v.$reset();
    },
  },
};
</script>