<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on" v-if="!edit">Ajouter Tache</v-btn>
      <v-btn small v-bind="attrs" v-on="on" icon v-if="edit">
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ titre }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="tacheForm" @submit.prevent="saveForm">
            <div>
              <div v-if="$v.nomTache.$dirty">
                <div class="error" v-if="!$v.nomTache.required">
                  Ce champ est requis
                </div>
              </div>
              <v-text-field v-model="$v.nomTache.$model" label="Nom"></v-text-field>
            </div>
            <div v-if="edit">
              <div v-if="$v.ordre.$dirty">
                <div class="error" v-if="!$v.ordre.required">
                  Ce champ est requis
                </div>
                <div class="error" v-if="!$v.ordre.numeric">
                  Veuillez entrer une valeur numérique
                </div>
              </div>
              <v-text-field v-model="$v.ordre.$model" label="Ordre" type="number" min="1"></v-text-field>
            </div>
            <div>
              <div v-if="$v.dateDebut.$dirty">
                <div class="error" v-if="!$v.dateDebut.required">
                  Ce champ est requis
                </div>
              </div>
              <DatePickerComponent v-on:dateSaved="saveDateDebut" v-bind:previousDate="dateDebut" label="Date de début">
              </DatePickerComponent>
            </div>
            <div>
              <div v-if="$v.dateFin.$dirty">
                <div class="error" v-if="!$v.dateFin.required">
                  Ce champ est requis
                </div>
              </div>
              <DatePickerComponent v-on:dateSaved="saveDateFin" v-bind:previousDate="dateFin" v-bind:minDate="dateDebut"
                label="Date de fin"></DatePickerComponent>
            </div>
            <div>
              <div v-if="$v.etatEcheancier.$dirty">
                <div class="error" v-if="!$v.etatEcheancier.required">
                  Ce champ est requis
                </div>
              </div>
              <v-select :items="etatEcheancierItems" label="Etat échéancier" v-model="etatEcheancier"></v-select>
            </div>
            <div>
              <div v-if="$v.avancement.$dirty">
                <div class="error" v-if="!$v.avancement.required">
                  Ce champ est requis
                </div>
              </div>
              <v-select v-model="avancement" :items="avancementItems" label="Avancement"></v-select>
            </div>
            <div>
              <div v-if="$v.responsable.$dirty">
                <div class="error" v-if="!$v.responsable.required">
                  Ce champ est requis
                </div>
                <div class="error" v-if="!$v.responsable.email">
                  Veuillez entrer une adresse mail valide
                </div>
              </div>
              <v-combobox v-model="$v.responsable.$model" :items="responsablesList" label="Responsable"></v-combobox>
            </div>
            <v-textarea v-model="commentaires" label="Commentaires"></v-textarea>
            <v-text-field v-model="appui" label="Avec l'appui de"></v-text-field>
            <v-row>
              <v-col>
                <v-btn type="submit">Enregistrer</v-btn>
              </v-col>
            </v-row>
            <v-col>
              <v-btn @click="close">Fermer</v-btn>
            </v-col>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DatePickerComponent from "../Dialog/DatePickerComponent";
import { requiredIf, required, email, numeric } from "vuelidate/lib/validators";
export default {
  name: "TacheFormComponent",
  components: { DatePickerComponent },
  props: [
    "btn_text",
    "titre",
    "tache_id",
    "edit",
    "activite_id",
    "objectif_id",
  ],
  data: () => ({
    dialog: false,
    tache: null,
    nomTache: "",
    dateDebut: "",
    ordre: null,
    dateFin: "",
    etatEcheancier: "",
    avancement: "",
    responsable: "",
    responsablesList: null,
    appui: "",
    commentaires: "",
    etatEcheancierItems: ["Respecté", "Devancé", "Retard"],
    avancementItems: ["En cours", "En continu", "Non débuté", "Terminé"],
    modalDatePicker1: "",
    modalDatePicker2: "",
  }),
  validations: {
    nomTache: {
      required,
    },
    ordre: {
      required: requiredIf(function () {
        return this.edit;
      }),
      numeric,
    },
    dateDebut: {
      required,
    },
    dateFin: {
      required,
    },
    etatEcheancier: {
      required,
    },
    avancement: {
      required,
    },
    responsable: {
      required,
      email,
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    ...mapActions(["addTache", "updateTache"]),
    ...mapGetters(["getTache", "getResponsablesList"]),
    editLoadTache() {
      var tache = this.tache;
      this.nomTache = tache.nom_tache;
      this.ordre = tache.ordre;
      this.dateDebut = tache.date_debut;
      this.dateFin = tache.date_fin;
      this.etatEcheancier = tache.etat_echeancier;
      this.avancement = tache.avancement;
      this.responsable = tache.responsable;
      this.appui = tache.appui;
      this.commentaires = tache.commentaires;
    },
    resetForm()
    {
      this.nomTache = null;
      this.ordre = null;
      this.dateDebut = null;
      this.dateFin = null;
      this.etatEcheancier = null;
      this.avancement = null;
      this.responsable = null;
      this.appui = null;
      this.commentaires = null;
    },
    saveForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (!this.$props.edit) {
          this.addNew();
        } else {
          this.update();
        }
        this.close();
      }
    },
    saveDateDebut(date) {
      this.dateDebut = date;
    },
    saveDateFin(date) {
      this.dateFin = date;
    },
    addNew() {
      var tache = {
        nom_tache: this.nomTache,
        date_debut: this.dateDebut,
        date_fin: this.dateFin,
        etat_echeancier: this.etatEcheancier,
        avancement: this.avancement,
        responsable: this.responsable,
        appui: this.appui,
        commentaires: this.commentaires,
        activite: this.$props.activite_id,
        objectif: this.$props.objectif_id,
      };
      this.addTache(tache);
      this.close();
    },
    update() {
      this.tache.nom_tache = this.nomTache;
      this.tache.date_debut = this.dateDebut;
      this.tache.date_fin = this.dateFin;
      this.tache.ordre = this.ordre;
      this.tache.etat_echeancier = this.etatEcheancier;
      this.tache.avancement = this.avancement;
      this.tache.responsable = this.responsable;
      this.tache.appui = this.appui;
      this.tache.commentaires = this.commentaires;
      this.tache.activite = this.$props.activite_id;
      this.tache.objectif = this.$props.objectif_id;
      this.updateTache(this.tache);
    },
    close() {
      this.resetForm()
      this.$v.$reset();
      if (!this.edit) {
        this.dateDebut = "";
        this.dateFin = "";
      }
      this.dialog = false;
    },
    open() {
      this.resetForm()
      this.$v.$reset();
      this.responsablesList = this.getResponsablesList();
      if (this.$props.edit == true) {
        this.tache = this.getTache()(
          this.tache_id
        );
        this.editLoadTache();
      }
    },
  },
};
</script>