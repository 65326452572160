<template>
  <div class="col-md-6 col-sm-12" style="margin:0 auto">
      <h1>Projets</h1>
      <br>
      <hr>
      <br>
      <ProjetsComponent></ProjetsComponent>
  </div>
</template>

<script>
import ProjetsComponent from "./ProjetsComponent"
export default {
  name: "UserProjetsComponent",
  components:{ProjetsComponent},
}
</script>