<template>
  <div>
    <NavMenuComponent :projet_id="projet_id"></NavMenuComponent>
    <div class="col-md-8" style="margin: 0 auto">
      <h1>Rapports</h1>
      <br />
      <hr />
      <br />
      <div>
        <v-card elevation="2" v-if="isAdmin()">
          <v-card-title
            ><span class="headline">Rapport Interne</span>
          </v-card-title>
          <v-card-text
            ><p>Rapport interne destiné aux gestionnaires du projet</p>
          </v-card-text>
          <v-card-actions
            ><v-btn text color="blue" @click="rapportInterne">Ouvrir</v-btn>
          </v-card-actions>
        </v-card>
        <br />
        <v-card elevation="2">
          <v-card-title
            ><span class="headline">Rapport bailleurs</span>
          </v-card-title>
          <v-card-text><p>Rapport destiné au client</p> </v-card-text>
          <v-card-actions
            ><v-btn text color="blue" @click="rapportExterne">Ouvrir</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NavMenuComponent from "../NavMenuComponent.vue";
export default {
  name: "RapportsComponent",
  components: { NavMenuComponent },
  props: ["projet_id"],
  methods: {
    ...mapGetters(["isAdmin"]),
    rapportInterne() {
      var data = this.$router.resolve({
        name: "RapportsView",
        params: { projet_id: this.projet_id, type_rapport: "interne" },
      });
      window.open(data.href, "blank");
    },
    rapportExterne() {
      var data = this.$router.resolve({
        name: "RapportsView",
        params: { projet_id: this.projet_id, type_rapport: "externe" },
      });
      window.open(data.href, "blank");
    },
  },
};
</script>