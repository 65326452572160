<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on">{{ btn_text }}</v-btn>
    </template>
    <v-card v-if="dataFetched">
      <v-card-title>{{ titre }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="projetForm" @submit.prevent="saveForm">
            <div>
              <div v-if="$v.nomProjet.$dirty">
                <div class="error" v-if="!$v.nomProjet.required">
                  Ce champ est requis
                </div>
              </div>
              <v-text-field
                v-model="$v.nomProjet.$model"
                label="Nom"
              ></v-text-field>
            </div>
            <div>
              <div v-if="$v.descriptionProjet.$dirty">
                <div class="error" v-if="!$v.descriptionProjet.required">
                  Ce champ est requis
                </div>
              </div>
              <v-textarea
                v-model="$v.descriptionProjet.$model"
                label="Description du projet"
              ></v-textarea>
            </div>
            <div v-if="!edit">
              <div class="error" v-if="noUserSelectedError">
                Vous devez selectionner au moins un utilisateur a affecter au
                projet
              </div>
              <v-select
                label="Utilisateur"
                :items="users"
                item-text="username"
                item-value="id"
                no-data-text="Aucun utilisateur disponible"
                v-on:input="addSelectedUser"
              ></v-select>
              <div class="UserChips">
                <v-chip
                  v-for="user in selectedUsers"
                  :key="user.id"
                  close
                  @click:close="removeSelectedUser(user)"
                >
                  {{ user.username }}
                </v-chip>
              </div>
            </div>
            <div v-if="edit">
              <div class="error" v-if="noUserAffectedError">
                Vous devez avoir au moins un utilisateur affecté au projet
              </div>
              <v-select
                label="Utilisateur"
                :items="users"
                item-text="username"
                item-value="id"
                no-data-text="Aucun utilisateur disponible"
                v-on:input="addSelectedUser"
              ></v-select>
              <div class="UserChips">
                <v-chip
                  v-for="user in affectedUsers"
                  :key="user.id"
                  color="red"
                  close
                  @click:close="disaffectUser(user)"
                >
                  {{ user.username }}
                </v-chip>
                <v-chip
                  v-for="user in selectedUsers"
                  :key="user.id"
                  close
                  @click:close="removeSelectedUser(user)"
                >
                  {{ user.username }}
                </v-chip>
              </div>
            </div>
            <div>
              <v-file-input
                v-model="logoProjet"
                accept="image/png"
                label="Logo projet"
              ></v-file-input>
              <p v-if="edit">{{ projetLogo() }}</p>
            </div>
            <v-row>
              <v-col>
                <v-btn type="submit">Enregistrer</v-btn>
              </v-col>
              <v-col>
                <v-btn v-on:click="close">Fermer</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "ProjetForm",
  props: ["btn_text", "titre", "projet_id", "edit"],
  data: () => ({
    dialog: false,
    dataFetched: false,
    projet: null,
    nomProjet: "",
    descriptionProjet: "",
    logoProjet: null,
    users: null,
    selectedUsers: [],
    affectedUsers: [],
    disaffectedUsers: [],
    selectedUserId: "",
  }),
  computed: {
    noUserSelectedError() {
      return this.selectedUsers.length == 0;
    },
    noUserAffectedError() {
      return this.affectedUsers.length == 0 && this.selectedUsers.length == 0;
    },
  },
  methods: {
    ...mapActions([
      "fetchUsers",
      "fetchProjetUsers",
      "addProjet",
      "commitAddProjet",
      "updateProjet",
      "addAssignations",
      "removeAssignations",
    ]),
    ...mapGetters(["getUser", "getProjetFromProjets"]),
    editLoadProjet() {
      this.nomProjet = this.projet.nom_projet;
      this.descriptionProjet = this.projet.description;
      this.affectedUsers = this.projet.utilisateurs.map((u) => u);
      var affectedUsersIds = this.projet.utilisateurs.map((u) => {
        return u.id;
      });
      this.users = this.users.filter((u) => !affectedUsersIds.includes(u.id));
    },
    addSelectedUser(id) {
      let userIndex = this.users.findIndex((u) => {
        return u.id == id;
      });
      var user = this.users[userIndex];
      this.selectedUsers.push(user);
      this.users.splice(userIndex, 1);
    },
    affectUser(id) {
      let userIndex = this.users.findIndex((u) => {
        return u.id == id;
      });
      var user = this.users[userIndex];
      this.affectedUsers.push(user);
      this.users.splice(userIndex, 1);
    },
    removeSelectedUser(user) {
      let userIndex = this.selectedUsers.findIndex((u) => {
        return u.id == user.id;
      });
      this.selectedUsers.splice(userIndex, 1);
      this.users.push(user);
    },
    disaffectUser(user) {
      let userIndex = this.affectedUsers.findIndex((u) => {
        return u.id == user.id;
      });
      this.affectedUsers.splice(userIndex, 1);
      this.disaffectedUsers.push(user);
      this.users.push(user);
    },
    projetLogo() {
      if (this.projet.logo_projet == null) {
        return "Aucun logo assigné";
      } else {
        return this.projet.logo_projet;
      }
    },
    saveForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (!this.$props.edit) {
          if (!this.noUserSelectedError) this.addNew();
        } else {
          if (!this.noUserAffectedError) this.update();
        }
      }
    },
    addNew() {
      var p = {
        nom_projet: this.nomProjet,
        description: this.descriptionProjet,
        logo_projet: this.logoProjet,
      };
      this.addProjet(p).then((addedProjet) => {
        if (addedProjet != null) {
          var assignations = this.selectedUsers.map((u) => {
            return { projet: addedProjet.id, utilisateur: u.id };
          });
          this.addAssignations(assignations).then(() => {
            this.close();
          });
        }
      });
    },
    update() {
      var projet = {
        id: this.projet.id,
        nom_projet: this.nomProjet,
        description: this.descriptionProjet,
        logo_projet: this.logoProjet,
      };
      this.updateProjet(projet);

      if (this.disaffectedUsers.length != 0) {
        var data = { projet_id: this.projet_id };
        data.utilisateurs = this.disaffectedUsers.map((u) => u.id);
        this.removeAssignations(data);
      }
      if (this.selectedUsers.length != 0) {
        var assignations = this.selectedUsers.map((u) => {
          return {
            projet: this.projet.id,
            utilisateur: u.id,
            utilisateur_object: u,
          };
        });
        this.addAssignations(assignations);
      }
      this.close();
    },
    open() {
      this.projet = this.getProjetFromProjets()(this.projet_id);
      this.fetchUsers().then((u) => {
        this.users = u;
        if (this.$props.edit == true) {
          this.editLoadProjet();
        }
        this.dataFetched = true;
      });
    },
    close() {
      this.dialog = false;
      this.nomProjet = "";
      this.descriptionProjet = "";
      this.logoProjet = null;
      this.selectedUsers = [];
      this.affectedUsers = [];
      this.disaffectedUsers = [];
      this.$v.$reset();
    },
  },
  watch: {
    dialog(value) {
      if (value) this.open();
    },
  },
  validations: {
    nomProjet: {
      required,
    },
    descriptionProjet: {
      required,
    },
  },
};
</script>

<style scoped>
.UserChips .v-chip {
  margin: auto 5px;
}
</style>