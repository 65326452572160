<template>
  <div>
    <div>
      <h3>Commentaires</h3>
      <div class="commentaires">
        <v-alert
          border="top"
          elevation="1"
          v-for="commentaire in commentaires"
          :key="commentaire.id"
        >
          {{ commentaire.text }}
          <div class="hideInPrint" style="float: right" v-if="!edit">
            <v-btn icon @click="editBtnClick(commentaire)"
              ><v-icon>mdi-pencil-outline</v-icon></v-btn
            >
            <v-btn icon color="red" @click="deleteBtnClick(commentaire)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
        </v-alert>
      </div>
    </div>
    <div class="controles hideInPrint col-md-6" style="margin: 0 auto">
      <h3>Ajouter commentaire</h3>
      <v-textarea v-model="textAreaValue"></v-textarea>
      <div v-if="!edit">
        <v-btn @click="commentaireBtnClick">Ajouter</v-btn>
      </div>
      <div v-if="edit">
        <v-btn @click="saveEditBtnClick">Enregistrer</v-btn>
        <v-btn @click="cancelEditBtnClick">Annuler</v-btn>
      </div>
    </div>
    <ConfirmationPromptComponent
      ref="confirmationPrompt"
      titre="Confirmer suppression"
      v-bind:text="promptText"
      v-bind:callback="promptCallback"
    ></ConfirmationPromptComponent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmationPromptComponent from "../Dialog/ConfirmationPromptComponent.vue";
export default {
  name: "RapportCommentairesComponent",
  components: { ConfirmationPromptComponent },
  props: ["commentaires", "type_rapport", "section", "projet_id"],
  data: () => ({
    textAreaValue: "",
    promptText: "",
    promptCallback: null,
    edit: false,
    editItem: null,
  }),
  methods: {
    ...mapActions(["addCommentaire", "deleteCommentaire", "updateCommentaire"]),
    ...mapGetters(["getUser"]),
    commentaireBtnClick() {
      var commentaire = {
        utilisateur: this.getUser().id,
        projet: this.projet_id,
        text: this.textAreaValue,
        section: this.section,
        type_rapport: this.type_rapport,
      };
      console.log(commentaire)
      this.addCommentaire(commentaire);
      this.textAreaValue = "";
    },
    editBtnClick(commentaire) {
      this.edit = true;
      this.textAreaValue = commentaire.text;
      this.editItem = commentaire;
    },
    cancelEditBtnClick() {
      this.edit = false;
      this.textAreaValue = "";
      this.editItem = null;
    },
    saveEditBtnClick() {
      var commentaire = {
        id: this.editItem.id,
        utilisateur: this.editItem.utilisateur,
        projet: this.editItem.projet,
        text: this.textAreaValue,
        section: this.editItem.section,
        type_rapport: this.editItem.type_rapport,
      };
      this.updateCommentaire(commentaire);
      this.cancelEditBtnClick();
    },
    deleteBtnClick(commentaire) {
      this.promptText = "Supprimer ce commentaire ?";
      this.promptCallback = () => {
        this.deleteCommentaire(commentaire);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
  },
};
</script>

<style >
.commentaires {
  text-align: left;
}

@media print {
  .controles {
    display: none;
  }

  .hideInPrint {
    display: none !important;
  }
}
</style>