<template>
  <div>
    <SalonsComponent v-if="allowed" :projet_id="projet_id"></SalonsComponent>
    <br />
    <br />
    <ProjetAuthorization
      :projet_id="projet_id"
      v-on:isAllowed="isAllowed"
    ></ProjetAuthorization>
  </div>
</template>

<script>
import SalonsComponent from "@/components/Salons/SalonsComponent";
import ProjetAuthorization from "@/components/User/ProjetAuthorization";
export default {
  name: "Salons",
  props: ["projet_id"],
  data: () => ({
    allowed: false,
  }),
  methods: {
    isAllowed(allowed) {
      this.allowed = allowed;
    },
  },
  components: { SalonsComponent, ProjetAuthorization },
};
</script>