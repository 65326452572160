<template>
  <v-app>
    <v-app-bar app white>
       <router-link to="/"><img height="100px" src="./assets/logo serdex.png" /></router-link>
      <v-toolbar-title></v-toolbar-title>
      <div class="menu">
        <router-link to="/">Accueil</router-link>
        <router-link v-show="!isLogged()" to="/connexion">Connexion</router-link>
        <router-link v-show="isLogged()" to="/projets">Projets</router-link>
        <router-link v-show="isLogged() && isAdmin()" to="/utilisateurs">Utilisateurs</router-link>
        <router-link v-show="isLogged()" to="/deconnexion">Deconnexion</router-link>
        <router-link to="/">A Propos</router-link>
        <router-link to="/">Contact</router-link>
      </div>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters} from "vuex";
export default {
  name: "App",
  methods: {
    ...mapGetters(["isLogged","isAdmin"]),
  },
};
</script>

<style>
@media print
{
  .v-toolbar
  {
    display: none !important;
    background-color: white;
  }

  .print-hide
  {
    display: none !important;  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.v-toolbar__content
{
  justify-content: space-between;
}

.menu
{
  margin-right: 20px;
}

 .menu a{
    margin: 0 20px;
  }

#nav {
  padding: 30px;
}

.v-application a {
  color: #2c3e50 !important;
  text-decoration:auto !important
}

.v-application a.router-link-exact-active {
  color: #7191c3 !important;
  text-decoration: underline !important;
  font-weight: bold;
}

#app h1,
h2,
h3 {
  color: #192646;
}

#app .v-data-table__wrapper > table > thead > tr {
  background-color: aliceblue;
}

#app .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem;
  font-weight: bold;
  color: #192646;
  text-align: center;
}

#app .v-data-table__wrapper > table > tbody > tr > td {
  color: black;
}

#app .v-data-table__wrapper > table > tbody > tr > .activite_nom,
.tache_nom {
  font-weight: bold;
}

.v-menu__content button
{
  background-color: white !important;
  box-shadow: none !important;
}

.v-dialog .v-card .error
{
  color: white;
  border-radius: 10px;
}

.v-dialog .v-card .v-card__title
{
  display: flex;
  justify-content: center;
}

</style>
