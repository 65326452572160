<template>
  <div>
    <GanttComponent
      v-if="allowed"
      :projet_id="projet_id"
    ></GanttComponent>
    <br />
    <br />
    <ProjetAuthorization
      :projet_id="projet_id"
      v-on:isAllowed="isAllowed"
    ></ProjetAuthorization>
  </div>
</template>

<script>
import GanttComponent from "@/components/Gantt/GanttComponent.vue";
import ProjetAuthorization from "@/components/User/ProjetAuthorization";
export default {
  name: "Gantt",
  props: ["projet_id"],
  data: () => ({
    allowed: false,
  }),
  methods: {
    isAllowed(allowed) {
      this.allowed = allowed;
    },
  },
  components: {
    GanttComponent,
    ProjetAuthorization,
  },
};
</script>