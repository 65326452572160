<template>
  <draggable v-model="projets" class="projets" v-if="dataFetched" tag="div" @change="ordreChanged">
    <div class="projet" v-for="projet in projets" :key="projet.id" :id="projet.id">
      <v-card elevation="2">
        <v-card-title><span class="headline">{{ projet.nom_projet }}</span>
          <v-menu right v-if="isAdmin()">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <ProjetForm titre="Modifier projet" btn_text="Modifier" :edit="true" :projet_id="projet.id">
                  </ProjetForm>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn text @click="deleteClick(projet)">Supprimer</v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text>
          <p>{{ projet.description }}</p>
          <div v-if="isAdmin()" class="userChips">
            <p style="font-weight: bold">Utilisateurs:</p>
            <v-chip v-for="utilisateur in projet.utilisateurs" :key="utilisateur.id">{{ utilisateur.username }}</v-chip>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="blue" @click="openProject(projet.id)">Plan stratégique</v-btn>
          <v-btn text color="black" @click="openGantt(projet.id)">Diagramme de Gantt</v-btn>
          <v-btn text color="black" @click="openSalons(projet.id)">Salons</v-btn>
          <v-btn text color="black" @click="openRapports(projet.id)">Rapport</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <v-alert dense type="info" v-if="projets == null || projets.length == 0"> Aucun projet disponible </v-alert>
    <ProjetForm v-if="isAdmin()" titre="Nouveau projet" btn_text="Nouveau projet" :edit="false"></ProjetForm>
    <ConfirmationPromptComponent ref="confirmationPrompt" titre="Confirmer suppression" v-bind:text="promptText"
      v-bind:callback="promptCallback"></ConfirmationPromptComponent>
    <v-snackbar v-model="snackbar" >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
import { mapActions, mapGetters } from "vuex";
import ProjetForm from "./ProjetForm";
import ConfirmationPromptComponent from "../Dialog/ConfirmationPromptComponent";
export default {
  name: "ProjetsComponent",
  components: { ProjetForm, ConfirmationPromptComponent, draggable },
  data: () => ({
    dataFetched: false,
    projets: null,
    promptCallback: null,
    promptText: "",
    text: "",snackbar:false
    
  }),
  methods: {
    ...mapGetters(["isAdmin", "getUser"]),
    ...mapActions([
      "fetchProjets",
      "updateOrdreProjets",
      "fetchUserProjets",
      "fetchProjetUsers",
      "setProjets",
      "deleteProjet",
    ]),
    openProject(projet_id) {
      this.$router.push({
        name: "PlanAction",
        params: { projet_id: projet_id },
      });
    },
    ordreChanged() {
      if (this.isAdmin()) {
        let ordonnancementProjets = this.projets.reduce(
          (accumulator, p, index) => {
            accumulator.push({ "id": p.id, "ordre": index + 1 });
            return accumulator;
          },
          []
        );
        this.updateOrdreProjets(ordonnancementProjets)
      }
      else {
        this.nonAdminOrderChangeFeedback()
      }

    },
    nonAdminOrderChangeFeedback() {
      this.text = "Ce changement ne sera pas enregistré car il est reservé aux administrateurs"
      this.snackbar = true;
    },

    openGantt(projet_id) {
      this.$router.push({ name: "Gantt", params: { projet_id: projet_id } });
    },
    openSalons(projet_id) {
      this.$router.push({ name: "Salons", params: { projet_id: projet_id } });
    },
    openRapports(projet_id) {
      this.$router.push({ name: "Rapports", params: { projet_id: projet_id } });
    },
    deleteClick(projet) {
      this.promptText = "Supprimer le projet " + projet.nom_projet + " ?";
      this.promptCallback = () => {
        this.deleteProjet(projet);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
  },
  created() {
    if (this.isAdmin()) {
      this.fetchProjets().then(p => {
        this.projets = p;
        this.dataFetched = true;
      });
    } else {
      this.fetchUserProjets(this.getUser().id).then(p => {
        this.projets = p
        this.dataFetched = true;
      });
    }
  },
};
</script>

<style scoped>
.projets {
  text-align: start;
}

.projet {
  margin: 10px 0;
}

.v-card__title {
  justify-content: space-between;
}

.userChips .v-chip {
  margin-right: 10px;
}
</style>