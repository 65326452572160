<template>
    <LoginComponent>
    </LoginComponent>
</template>

<script>
import LoginComponent from "@/components/Authentication/LoginComponent.vue"
export default {
    name:"Login",
    components:{LoginComponent}
}
</script>