<template>
  <div>
    <PlanActionComponent
      v-if="dataFetched && allowed"
      :projet="projet"
    ></PlanActionComponent>
    <br/>
    <br/>
    <ProjetAuthorization
      :projet_id="projet_id"
      v-on:isAllowed="isAllowed"
    ></ProjetAuthorization>
  </div>
</template>

<script>
import PlanActionComponent from "@/components/PlanAction/PlanActionComponent";
import ProjetAuthorization from "@/components/User/ProjetAuthorization";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PlanAction",
  components: { PlanActionComponent, ProjetAuthorization },
  props: ["projet_id"],
  data: () => ({
    projet: null,
    allowed: false,
    dataFetched: false,
  }),
  methods: {
    ...mapGetters(["getUser"]),
    ...mapActions(["fetchProjet"]),
    isAllowed(allowed) {
      this.allowed = allowed;
      if(allowed)
        this.fetchData()
    },
    fetchData() {
      this.fetchProjet(this.projet_id).then((p) => {
        this.projet = p;
        this.dataFetched = true;
      });
    },
  },
};
</script>