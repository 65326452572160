import axios from "axios"
import { loginUrl,logoutUrl,userInfosUrl, usersUrl, userUpdatePasswordUrl, userWithoutProjectUrl, userUrl, registerUrl, registerAdminUrl, resetPasswordDemandUrl, resetPasswordCheckLinkUrl, resetPasswordActionUrl, userAssignationsUrl } from "../urls"

const state = {
    user: null,
    users: null
}

const getters = {
    getUser(state) {
        return state.user
    },
    getUserFromUsers: (state) => (user_id) => {
        return state.users.find(u => u.id == user_id)
    },
    getUsers(state) {
        return state.users
    },
    isLogged(state, getters) {
        if (getters.getUser != null)
            return true
        else
            return false
    },
    isAdmin(state, getters) {
        return getters.getUser.is_superuser
    }
}
const actions = {
    async fetchUser({ commit }, credentials) {
        // var user = await axios.post(loginUrl, credentials, { withCredentials: true }).then((reponse) => {
        var user = await axios.post(loginUrl, credentials).then((reponse) => {
            if (reponse.status == 200) {
                var user = reponse.data
                commit("setUser", user);
                return user;
            }
            return null;
        }).catch(() => {

            return null;
        });
        return user;
    },

    async getUserInfo({ commit }) {
        return await axios.get(userInfosUrl).then((reponse) => {
            if (reponse.status == 200) {
                const user = reponse.data
                commit("setUser", user);
                return user
            }
        }).catch(() => {
            return null;
        });
    },

    async fetchUsers({ commit }) {
        var users = await axios.get(usersUrl).then((reponse) => {
            if (reponse.status == 200) {
                var users = reponse.data
                commit("setUsers", users);
                return users;
            }
            return null;
        }).catch(() => {
            return null;
        });
        return users;
    },

    //eslint-disable-next-line no-unused-vars
    async fetchUsersWithoutProjet({ commit }) {
        var users = await axios.get(userWithoutProjectUrl).then((reponse) => {
            if (reponse.status == 200) {
                var users = reponse.data
                return users;
            }
            return null;
        }).catch(() => {
            return null;
        });
        return users;
    },

    //eslint-disable-next-line no-unused-vars
    async fetchUserAssignations({ commit }, user) {
        var url = userAssignationsUrl.replace("{id}", user.id);
        return await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                var affectations = reponse.data
                return affectations;
            }
            return null;
        }).catch(() => {
            return null;
        });
    },

    async registerUser({ commit }, user) {
        var result = await axios.post(registerUrl, user).then((reponse) => {
            if (reponse.status == 201) {
                var user = reponse.data
                commit("addUser", user);
                return user;
            }
            return null;
        }).catch(() => {

            return null;
        });
        return result;
    },

    async registerAdminUser({ commit }, user) {
        var result = await axios.post(registerAdminUrl, user).then((reponse) => {
            if (reponse.status == 201) {
                var user = reponse.data
                commit("addUser", user);
                return user;
            }
            return null;
        }).catch(() => {

            return null;
        });
        return result;
    },

    //eslint-disable-next-line no-unused-vars
    async resetPasswordDemand({ commit }, data) {
        var result = await axios.post(resetPasswordDemandUrl, data).then((response) => {
            if (response.status == 201) {
                return true
            }
            return false
        }).catch((error) => {
            console.log(error)
            return false;
        });
        return result
    },

    //eslint-disable-next-line no-unused-vars
    async resetPasswordAction({ commit }, data) {
        var result = await axios.post(resetPasswordActionUrl, data).then((response) => {
            if (response.status == 200) {
                return true
            }
            return false
        }).catch((error) => {
            console.log(error)
            return false;
        });
        return result
    },

    //eslint-disable-next-line no-unused-vars
    async checkResetPasswordLink({ commit }, data) {
        var result = await axios.post(resetPasswordCheckLinkUrl, data).then((response) => {
            if (response.status == 200) {
                return response.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null;
        });
        return result
    },

    async updateUser({ commit }, user) {
        var url = userUrl.replace("{id}", user.id);
        var result = await axios.put(url, user).then((response) => {
            if (response.status == 200) {
                commit("updateUser", response.data);
                return true
            }
            return false
        }).catch((error) => {
            console.log(error)
            return false;
        });
        return result
    },

    //eslint-disable-next-line no-unused-vars
    async updateUserPassword({ commit }, data) {
        var url = userUpdatePasswordUrl.replace("{id}", data.user_id);
        var result = await axios.put(url, data).then((response) => {
            if (response.status == 200) {
                return true
            }
            return false
        }).catch((error) => {
            console.log(error)
            return false;
        });
        return result
    },

    async logout({ commit }) {
        return await axios.post(logoutUrl).then((reponse) => {
            if (reponse.status == 200) {
                commit("logout");
                return true
            }
        }).catch(() => {
            return false;
        });
    },

    deleteUser({ commit }, user) {
        var url = userUrl.replace("{id}", user.id);
        axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteUser", user);
            }
        });
    },

    setUser({ commit }, user) {
        commit("setUser", user);
    },

    setUsers({ commit }, users) {
        commit("setUsers", users);
    },

}
const mutations = {
    setUser: (state, user) => {
        state.user = user
    },
    setUsers: (state, users) => {
        state.users = users
    },
    addUser: (state, user) => {
        state.users.push(user)
    },
    updateUser: (state, updatedUser) => {
        var userIndex = state.users.findIndex(u => u.id == updatedUser.id)
        var user = state.users[userIndex]
        user.username = updatedUser.username
        user.email = updatedUser.email
        user.compagnie = updatedUser.compagnie
        user.is_superuser = updatedUser.is_superuser
    },
    deleteUser: (state, user) => {
        var userIndex = state.users.findIndex(u => u.id == user.id)
        state.users.splice(userIndex, 1)
    },
    logout: (state) => {
        state.user = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}