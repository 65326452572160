<template>
<div>
    <h2>Page introuvable</h2>
    <div>La page que vous recherchez est introuvable sur ce site</div>
</div>
</template>

<script>
export default {
    name:"NotFound"
}
</script>