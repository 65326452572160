var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataFetched)?_c('v-data-table',{attrs:{"items":_vm.rappels,"headers":_vm.headers,"search":_vm.search,"custom-filter":_vm.searchFilter,"locale":"fr","sort-by":"date_debut","sort-desc":true,"no-data-text":"Aucune donnée disponible","no-results-text":"Aucun résultat correspondant","footer-props":{
      'items-per-page-text': 'Lignes par page:',
      'page-text': '{0}-{1} sur {2}',
      'items-per-page-all-text': 'Toutes',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"label":"Recherche"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.type_objet",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.objet_rappel)+" ")]}},{key:"item.objet",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getObjetRappel(item))+" ")]}},{key:"item.date_derniere_execution",fn:function(ref){
    var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.date_derniere_execution))+" ")])]}},{key:"item.date_prochaine_execution",fn:function(ref){
    var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.date_prochaine_execution))+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('td',[_c('v-btn',{attrs:{"small":"","color":"red","icon":""},on:{"click":function($event){return _vm.deleteAction(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,false,3284301084)}):_vm._e(),_c('RappelsFormComponent',{attrs:{"btn_text":"Rappels","titre":"Nouveau Rappel","isFromRappelsPage":true,"projet_id":_vm.projet_id}}),_c('ConfirmationPromptComponent',{ref:"confirmationPrompt",attrs:{"titre":"Confirmer suppression","text":_vm.promptText,"callback":_vm.promptCallback}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }