import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import moment from 'moment'
import vuetify from "./plugins/vuetify"
import vuelidate from 'vuelidate'
import ApexCharts from 'vue-apexcharts'
import VueApexCharts from 'vue-apexcharts';
import store from "./store"
import setupAxios from './axios_interceptor/interceptor'

Vue.config.productionTip = false
Vue.use(router)
Vue.use(vuelidate)
Vue.use(ApexCharts)
Vue.component("apexchart", VueApexCharts)
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;

setupAxios()

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')