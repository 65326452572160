<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on" v-if="!edit">Ajouter utilisateur</v-btn>
      <v-btn small v-bind="attrs" v-on="on" icon v-if="edit">
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ titre }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="objectifForm" @submit.prevent="saveForm">
            <div>
              <div v-if="$v.username.$dirty">
                <div class="error" v-if="!$v.username.required">
                  Ce champ est requis
                </div>
              </div>
              <v-text-field v-model.trim="$v.username.$model" label="Nom d'utilisateur"></v-text-field>
            </div>
            <div>
              <div v-if="$v.email.$dirty">
                <div class="error" v-if="!$v.email.required">
                  Ce champ est requis
                </div>
                <div class="error" v-if="!$v.email.email">
                  Veuillez entrer une adresse mail valide
                </div>
              </div>
              <v-text-field v-model="$v.email.$model" label="Adresse mail"></v-text-field>
            </div>
            <div>
              <div v-if="$v.compagnie.$dirty">
                <div class="error" v-if="!$v.compagnie.required">
                  Ce champ est requis
                </div>
              </div>
              <v-text-field v-model="$v.compagnie.$model" label="Compagnie"></v-text-field>
            </div>
            <div>
              <div v-if="$v.typeUtilisateur.$dirty">
                <div class="error" v-if="!$v.typeUtilisateur.required">
                  Ce champ est requis
                </div>
              </div>
              <v-select :items="typeUtilisateurItems" v-model="$v.typeUtilisateur.$model" label="Type d'utilisateur">
              </v-select>
            </div>
            <div>
              <div v-if="edit">
                <v-switch v-model="passwordSwitch" label="Mettre a jour le mot de passe">Changer mot de passe</v-switch>
              </div>
              <div v-if="showPasswordInputs()">
                <div>
                  <div v-if="$v.motDePasse.$dirty">
                    <div class="error" v-if="!$v.motDePasse.required">
                      Ce champ est requis
                    </div>
                    <div class="error" v-if="!$v.motDePasse.minLength">
                      Le mot de passe doit contenir au moins 4 caractères
                    </div>
                  </div>
                  <v-text-field v-model="$v.motDePasse.$model" :type="passwordVisible ? 'text' : 'password'"
                    :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="passwordVisible = !passwordVisible" label="Mot de passe"></v-text-field>
                </div>
                <div>
                  <div v-if="$v.cMotDePasse.$dirty">
                    <div class="error" v-if="!$v.cMotDePasse.required">
                      Ce champ est requis
                    </div>
                    <div class="error" v-if="!$v.cMotDePasse.minLength">
                      Le mot de passe doit contenir au moins 4 caractères
                    </div>
                    <div class="error" v-if="!$v.cMotDePasse.sameAsPassword">
                      Les deux mots de passe doivent correspondre
                    </div>
                  </div>
                  <v-text-field v-model="$v.cMotDePasse.$model" :type="cPasswordVisible ? 'text' : 'password'"
                    :append-icon="cPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="cPasswordVisible = !cPasswordVisible" label="Confirmer mot de passe"></v-text-field>
                </div>
              </div>
            </div>
            <v-row>
              <v-col>
                <v-btn type="submit">Enregistrer</v-btn>
              </v-col>
            </v-row>
            <v-col>
              <v-btn @click="close">Fermer</v-btn>
            </v-col>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
//import { mapActions, mapGetters } from "vuex";
import {
  required,
  requiredIf,
  email,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "UserFormComponent",
  props: ["user_id", "titre", "edit"],
  data: () => ({
    dialog: false,
    user: null,
    username: "",
    email: "",
    compagnie: "",
    motDePasse: "",
    cMotDePasse: "",
    passwordSwitch: false,
    passwordVisible: false,
    cPasswordVisible: false,
    typeUtilisateur: "",
    typeUtilisateurItems: ["Utilisateur", "Administrateur"],
  }),
  validations: {
    username: {
      required,
    },
    email: {
      required,
      email,
    },
    compagnie: {
      required,
    },
    typeUtilisateur: {
      required,
    },
    motDePasse: {
      required: requiredIf(function () {
        return this.showPasswordInputs();
      }),
      minLength: minLength(4),
    },
    cMotDePasse: {
      required: requiredIf(function () {
        return this.showPasswordInputs();
      }),
      minLength: minLength(4),
      sameAsPassword: sameAs("motDePasse"),
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    ...mapActions([
      "registerUser",
      "registerAdminUser",
      "updateUser",
      "updateUserPassword",
    ]),
    ...mapGetters(["getUserFromUsers"]),
    editLoadUser() {
      this.username = this.user.username;
      this.email = this.user.email;
      this.compagnie = this.user.compagnie;
      this.typeUtilisateur = this.user.is_superuser
        ? "Administrateur"
        : "Utilisateur";
    },
    showPasswordInputs() {
      if (!this.edit) return true;
      else {
        return this.passwordSwitch;
      }
    },
    saveForm() {
      this.$v.$touch();
      console.log(this.$v);
      if (!this.$v.$invalid) {
        if (!this.$props.edit) {
          this.addNew();
        } else {
          this.update();
        }
      }
    },
    addNew() {
      var user = {
        username: this.username,
        email: this.email,
        compagnie: this.compagnie,
        password: this.motDePasse,
      };
      if (this.typeUtilisateur == "Utilisateur") this.registerUser(user);
      else this.registerAdminUser(user);
      this.close();
    },
    update() {
      var user = {
        id: this.user.id,
        username: this.username,
        email: this.email,
        compagnie: this.compagnie,
      };
      if (this.typeUtilisateur == "Utilisateur") user.is_superuser = false;
      else if (this.typeUtilisateur == "Administrateur")
        user.is_superuser = true;
      this.updateUser(user);
      if (this.passwordSwitch == true) {
        var data = { user_id: this.user.id, new_password: this.motDePasse };
        this.updateUserPassword(data);
      }
      this.close();
    },
    close() {
      this.$refs.objectifForm.reset();
      this.$v.$reset();
      this.dialog = false;
    },
    open() {
      if (this.edit) {
        this.user = this.getUserFromUsers()(this.user_id);
        this.editLoadUser();
      }
    },
  },
};
</script>