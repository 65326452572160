<template>
<div>
  <RapportsComponent
    v-if="allowed"
    :projet_id="projet_id"
  ></RapportsComponent>
  <br />
  <br />
  <ProjetAuthorization
    :projet_id="projet_id"
    v-on:isAllowed="isAllowed"
  ></ProjetAuthorization>
</div>
</template>

<script>
import RapportsComponent from "@/components/Rapports/RapportsComponent";
import ProjetAuthorization from "@/components/User/ProjetAuthorization";
export default {
  name: "Rapports",
  components: { RapportsComponent, ProjetAuthorization },
  props: ["projet_id"],
  data: () => ({
     allowed: false,
  }),
  methods: {
     isAllowed(allowed) {
      this.allowed = allowed;
    },
  },
};
</script>