<template>
    <RappelsComponent :projet_id="projet_id"></RappelsComponent>
</template>

<script>
import RappelsComponent from "@/components/PlanAction/RappelsComponent"
export default {
    name:"Rappels",
    components:{RappelsComponent},
    props:["projet_id"]
}
</script>