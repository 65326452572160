import axios from "axios"
import { commentairesUrl, commentaireUrl } from "../urls"

const state = {
    commentaires: null
}

const getters = {
    getCommentaires: (state) => {
        return state.commentaires
    },
}

const actions = {
    async fetchCommentaires({ commit }, data) {
        var url = commentairesUrl.replace("{id}", data.projet_id);
        url = url.replace("{type}", data.type_rapport)
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setCommentaires", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null
        });
        return reponse;
    },

    //eslint-disable-next-line no-unused-vars
    async addCommentaire({ commit }, commentaire) {
        var url = commentairesUrl.replace("{id}", commentaire.projet);
        url = url.replace("{type}", commentaire.type_rapport)
        return await axios.post(url, commentaire).then((response) => {
            if (response.status == 201) {
                var commentaire = response.data
                commit("addCommentaire", commentaire);
                return commentaire
            }
            return null
        }).catch(error => {
            console.log(error)
            return null
        });
    },


    async deleteCommentaire({ commit }, commentaire) {
        var url = commentaireUrl.replace("{id}", commentaire.id);
        return await axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteCommentaire", commentaire);
                return true
            }
            return false
        });
    },

    async updateCommentaire({ commit }, commentaire) {
        var url = commentaireUrl.replace("{id}", commentaire.id);
        return await axios.put(url, commentaire).then((response) => {
            if (response.status == 200) {
                commit("updateCommentaire", response.data);
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },
}
const mutations = {
    setCommentaires: (state, commentaires) => { state.commentaires = commentaires },
    addCommentaire: (state, commentaire) => {
        state.commentaires.push(commentaire);
    },
    deleteCommentaire(state, commentaire) {
        var commentaireIndex = state.commentaires.findIndex(c => c.id == commentaire.id)
        state.commentaires.splice(commentaireIndex, 1);
    },
    updateCommentaire(state, commentaire) {
        var commentaireIndex = state.commentaires.findIndex(c => c.id == commentaire.id)
        var commentaireToUpdate = state.commentaires[commentaireIndex]
        commentaireToUpdate.id = commentaire.id
        commentaireToUpdate.utilisateur = commentaire.utilisateur
        commentaireToUpdate.projet = commentaire.projet
        commentaireToUpdate.text = commentaire.text
        commentaireToUpdate.section = commentaire.section
        commentaireToUpdate.type_rapport = commentaire.type_rapport
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}