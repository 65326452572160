<template>
  <div>
    <v-list>
      <v-list-group :value="true">
        <template v-slot:activator>
          <v-list-item>
            <v-menu right v-model="menu" class="print-hide">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title
                    ><ObjectifFormComponent
                      btn_text="Modifier"
                      titre="Modifier objectif"
                      :edit="true"
                      :projet_id="objectif.projet"
                      :objectif_id="objectif.id"
                      v-on:updated="menu=false"
                    ></ObjectifFormComponent
                  ></v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isAdmin()">
                  <v-list-item-title>
                    <v-btn text @click="deleteClick(objectif)"
                      >Supprimer</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-list-item-title class="objectif_name">
              <h2>{{ objectif.nom_objectif }}</h2>
              <h3>{{ objectif.indicateur }}</h3>
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item>
          <v-list-item-content
            ><ActiviteComponent
              :objectif_id="objectif.id"
              :projet_id="objectif.projet"
            ></ActiviteComponent
          >
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <br />
    <br />
    <ConfirmationPromptComponent
      ref="confirmationPrompt"
      titre="Confirmer suppression"
      v-bind:text="promptText"
      v-bind:callback="promptCallback"
    ></ConfirmationPromptComponent>
  </div>
</template>

<script>
import ActiviteComponent from "./ActiviteComponent";
import ObjectifFormComponent from "./ObjectifFormComponent";
import ConfirmationPromptComponent from "../Dialog/ConfirmationPromptComponent";
import { mapActions,mapGetters } from "vuex";
export default {
  name: "ObjectifsComponent.vue",
  components: {
    ActiviteComponent,
    ObjectifFormComponent,
    ConfirmationPromptComponent,
  },
  props: ["objectif"],
  data: () => ({
    promptText: "",
    promptCallback: null,
    menu:false
  }),
  methods: {
    ...mapActions(["deleteObjectif"]),
        ...mapGetters(["isAdmin"]),
    deleteClick(objectif) {
      this.promptText = "Supprimer l'objectif " + objectif.nom_objectif + " ?";
      this.promptCallback = () => {
        this.deleteObjectif(objectif);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
  },
};
</script>
