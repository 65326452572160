<template>
    <UserProjetsComponent></UserProjetsComponent>
</template>

<script>
import UserProjetsComponent from "@/components/User/UserProjetsComponent";
export default {
    name:"Projets",
    components:{UserProjetsComponent},
}
</script>