import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Projets from '../views/Projets.vue'
import PlanAction from '../views/PlanAction.vue'
import Gantt from '../views/Gantt.vue'
import Salons from '../views/Salons.vue'
import Rappels from '../views/Rappels.vue'
import Rapports from '../views/Rapports.vue'
import RapportView from '../views/RapportView.vue'
import Utilisateurs from '../views/Utilisateurs.vue'
import PasswordReset from '../views/PasswordReset.vue'
import PasswordResetAction from '../views/PasswordResetAction.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/projets',
        name: 'Projets',
        component: Projets,
        meta: { requiresAuth: true }
    },
    {
        path: '/utilisateurs',
        name: 'Utilisateurs',
        component: Utilisateurs,
        meta: { requiresAdminAuth: true, requiresAuth: true }
    },
    {
        path: '/planaction/:projet_id',
        name: 'PlanAction',
        component: PlanAction,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/gantt/:projet_id',
        name: 'Gantt',
        component: Gantt,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/rapports/:projet_id',
        name: 'Rapports',
        component: Rapports,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/rapports/:projet_id/:type_rapport',
        name: 'RapportsView',
        component: RapportView,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/salons/:projet_id',
        name: 'Salons',
        component: Salons,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/rappels/:projet_id',
        name: 'Rappels',
        component: Rappels,
        props: true,
        meta: { requiresAdminAuth: true, requiresAuth: true }
    },
    {
        path: '/connexion',
        name: 'Login',
        component: Login
    },
    {
        path: '/connexion/reinitialiser',
        name: 'PasswordReset',
        component: PasswordReset
    },
    {
        path: '/connexion/reinitialiser/:reinitialisation_link',
        name: 'PasswordResetAction',
        props: true,
        component: PasswordResetAction
    },
    {
        path: '/deconnexion',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/page-introuvable',
        name: '404',
        component: NotFound
    },
    {
        path: '*',
        redirect: '/page-introuvable',
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach(async (to, from, next) => {
    await store.dispatch("getUserInfo")
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLogged) {
            if (to.matched.some(record => record.meta.requiresAdminAuth)) {
                if (store.getters.isAdmin) {
                    next()
                } else {
                    next({ path: '/connexion', query: { redirect: to.fullPath, admin_redirect: true } })
                }
            } else {
                next()
            }
        } else {
            next({ path: '/connexion', query: { redirect: to.fullPath } })
        }
    } else
        next()
})

//eslint-disable-next-line no-unused-vars
router.afterEach((to, from, next) => {
    Vue.nextTick(()=>{
        document.title="Serdex - Hélico en action"
    })
})

export default router