import Vuex from "vuex"
import Vue from "vue"
import authentication from "./modules/authentication/authentication"
import projets from "./modules/projets/projets"
import objectifs from "./modules/projets/objectifs"
import salons from "./modules/salons/salons"
import rappels from "./modules/projets/rappels"
import commentaires from "./modules/projets/commentaires"


Vue.use(Vuex)

export default new Vuex.Store({
    modules: { authentication, projets, salons, objectifs, rappels, commentaires }
})