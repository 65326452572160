<template>
  <div class="col-md-4" style="margin: 0 auto">
    <h1>Connexion</h1>
    <v-alert type="error" v-if="error">Erreur dans l'adresse email ou le mot de passe</v-alert>
    <v-alert type="error" v-if="admin_redirect">Vous devez être connecté en tant qu'administrateur pour accéder a cette
      page</v-alert>
    <v-form @submit="connexion">
      <v-text-field v-model="email" label="Adresse email"> </v-text-field>
      <v-text-field v-model="password" :type="passwordVisible ? 'text' : 'password'"
        :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'" @click:append="passwordVisible = !passwordVisible"
        label="Mot de passe"></v-text-field>
      <br />
      <v-btn type="submit">Connexion</v-btn>
    </v-form>
    <br />
    <div>
      <router-link to="/connexion/reinitialiser">Mot de passe oublié</router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginComponent",
  data: () => ({
    email: "",
    password: "",
    passwordVisible: false,
    error: false,
  }),
  methods: {
    ...mapActions(["fetchUser", "getUser"]),
    ...mapGetters(["isAdmin"]),
    connexion: function (e) {
      e.preventDefault();
      var credentials = { username: this.email, password: this.password };
      this.fetchUser(credentials).then((user) => {
        if (user == null) {
          this.error = true;
        } else {
          this.$router.push({ path: "/projets" });
        }
      });
    },
  },
  computed: {
    admin_redirect() {
      return this.$route.query.admin_redirect;
    },
  },
};
</script>