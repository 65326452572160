<template>
  <v-dialog style="width:50%" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon small>
        <v-icon>mdi-swap-vertical-bold</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Convertir activité en tâche</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="activiteToTacheForm" @submit.prevent="saveForm">
            <div>
              <div v-if="$v.activiteALier.$dirty">
                <div class="error" v-if="!$v.activiteALier.required">
                  Ce champ est requis
                </div>
              </div>
              <v-select label="Activité a laquelle lier la nouvelle tâche" :items="activitesAvailableItems"
                item-text="nom_activite" item-value="id" v-model="$v.activiteALier.$model"
                no-data-text="Aucune activité disponible"></v-select>
            </div>
            <div v-if="activiteHasTaches">
              <div v-if="$v.activiteLierTachesA.$dirty">
                <div class="error" v-if="!$v.activiteLierTachesA.required">
                  Ce champ est requis
                </div>
              </div>
              <v-select label="Activité a laquelle lier les tâches de cette activité" :items="activitesAvailableItems"
                item-text="nom_activite" item-value="id" v-model="$v.activiteLierTachesA.$model"
                no-data-text="Aucune activité disponible"></v-select>
            </div>
            <v-row>
              <v-col>
                <v-btn type="submit">Enregistrer</v-btn>
              </v-col>
              <v-col>
                <v-btn @click="close">Fermer</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
    <ConfirmationPromptComponent ref="confirmationPrompt" :titre="promptTitle" v-bind:text="promptText"
      v-bind:callback="promptCallback"></ConfirmationPromptComponent>
  </v-dialog>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from 'vuex';
import ConfirmationPromptComponent from "../Dialog/ConfirmationPromptComponent";
export default {
  name: "ActiviteToTacheFormComponent",
  components: { ConfirmationPromptComponent },
  props: ["activite"],
  computed: {
    activitesAvailableItems() {
      let idActiviteAConvertir = this.activite.id;
      // let idObjectifConcerne = this.activite.objectif;
      // return this.activites.filter(
      //   (a) => a.id != idActiviteAConvertir && a.objectif == idObjectifConcerne
      // );
      return this.getActivites().filter(
        (a) => a.id != idActiviteAConvertir
      );
    },
    activiteHasTaches() {
      return this.getTaches().filter((t) => t.activite == this.activite.id).length != 0
    }
  },
  data: () => ({
    activiteALier: null,
    activiteLierTachesA: null,
    dialog: false,
    promptCallback: null,
    promptText: "",
    promptTitle: "",
  }),
  methods: {
    ...mapActions(["convertActiviteToTache"]),
    ...mapGetters(["getActivites", "getTaches"]),
    saveForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.convert()
      }
    },

    convert() {
      this.promptTitle = "Confirmer conversion"
      this.promptText = "Convertir l'activité " + this.activite.nom_activite + " en tâche ?";
      this.promptCallback = async () => {
        let payload = { "new_linked_activite_id": this.activiteALier, "activite_to_link_taches_to_id": this.activiteLierTachesA }
        let data = { payload: payload, activite: this.activite }
        console.log(data)
        let result = await this.convertActiviteToTache(data);
        if (result)
          this.close()
      };
      this.$refs.confirmationPrompt.dialog = true;
    },

    close() {
      this.activiteALier = null
      this.activiteLierTachesA = null
      this.$v.$reset();
      this.dialog = false;
    },
  },
  validations: {
    activiteALier: {
      required,
    },
    activiteLierTachesA: {
      required: requiredIf(function () {
        return this.activiteHasTaches
      }),
    },
  },
};
</script>