<template>
  <div>
    <v-data-table
      v-if="dataFetched"
      :items="users"
      :headers="headers"
      :search="search"
      :custom-filter="searchFilter"
      locale="fr"
      no-data-text="Aucune donnée disponible"
      no-results-text="Aucun résultat correspondant"
      :footer-props="{
        'items-per-page-text': 'Lignes par page:',
        'page-text': '{0}-{1} sur {2}',
        'items-per-page-all-text': 'Toutes',
      }"
    >
      <template v-slot:top>
        <v-text-field v-model="search" label="Recherche"></v-text-field>
      </template>

      <template #item.projets="{ item }">
        <div class="d-flex flex-column projetschips">
          <v-chip v-for="projet in item.projets" :key="projet.id">
            <router-link :to="'/planaction/' + projet.id">{{
              projet.nom_projet
            }}</router-link>
          </v-chip>
        </div>
      </template>

      <template #item.type="{ item }">
        {{ getTypeUtilisateur(item) }}
      </template>

      <template #item.actions="{ item }">
        <td>
          <UserFormComponent
            titre="Modifier utilisateur"
            :user_id="item.id"
            :edit="true"
          ></UserFormComponent>
          <v-btn small color="red" icon @click="deleteAction(item)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </td>
      </template>
    </v-data-table>
    <UserFormComponent
      btn_text="Ajouter Utilisateur"
      titre="Nouvel utilisateur"
      :edit="false"
    ></UserFormComponent>
    <ConfirmationPromptComponent
      ref="confirmationPrompt"
      titre="Confirmer suppression"
      v-bind:text="promptText"
      v-bind:callback="promptCallback"
    ></ConfirmationPromptComponent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserFormComponent from "./UserFormComponent.vue";
import ConfirmationPromptComponent from "../Dialog/ConfirmationPromptComponent.vue";
export default {
  name: "UserTableComponent",
  components: {
    UserFormComponent,
    ConfirmationPromptComponent,
  },
  data: () => ({
    users: null,
    search: "",
    dataFetched: false,
    promptText: "",
    promptCallback: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: "Nom d'utilisateur",
          value: "username",
        },
        { text: "Email", value: "email" },
        { text: "Compagnie", value: "compagnie" },
        { text: "Projets", value: "projets" },
        { text: "Type utilisateur", value: "type" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  methods: {
    ...mapActions(["fetchUsers", "fetchUserProjets", "setUsers", "deleteUser"]),
    ...mapGetters(["getUsers"]),
    searchFilter(value, search, entree) {
      let inUsername = RegExp(search, "i").test(entree.username);
      let inEmail = RegExp(search, "i").test(entree.email);
      let inCompagnie = RegExp(search, "i").test(entree.compagnie);
      return inUsername || inEmail || inCompagnie;
    },
    getTypeUtilisateur(utilisateur) {
      if (utilisateur.is_superuser) return "Administrateur";
      else return "Utilisateur";
    },
    deleteAction(user) {
      this.promptText = "Supprimer l'utilisateur " + user.username + " ?";
      this.promptCallback = () => {
        this.deleteUser(user);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
  },
  created() {
    this.fetchUsers().then((users) => {
      Promise.all(
        users.map(async (u) => {
          var projets = await this.fetchUserProjets(u.id);
          return {
            ...u,
            projets: projets,
          };
        })
      ).then((usersWithProjects) => {
        this.setUsers(usersWithProjects);
        this.users = usersWithProjects;
        this.dataFetched = true;
      });
    });
  },
};
</script>

<style scoped>
.projetschips .v-chip {
  margin: 5px auto;
}
</style>