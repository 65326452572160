<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
     <v-btn small v-bind="attrs" v-on="on" v-if="!edit">Ajouter Salon</v-btn>
    <v-btn small v-bind="attrs" v-on="on" icon v-if="edit"><v-icon>mdi-pencil-outline</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title>{{ titre }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="salonForm" @submit.prevent="saveForm">
            <div>
              <div v-if="$v.nomSalon.$dirty">
                <div class="error" v-if="!$v.nomSalon.required">
                  Ce champ est requis
                </div>
              </div>
              <v-text-field
                v-model="$v.nomSalon.$model"
                label="Nom"
              ></v-text-field>
            </div>
            <div>
              <div v-if="$v.date.$dirty">
                <div class="error" v-if="!$v.date.required">
                  Ce champ est requis
                </div>
              </div>
            <DatePickerComponent
                v-on:dateSaved="saveDate"
                v-bind:previousDate="date"
                label="Date"
              ></DatePickerComponent>
            </div>
            <div>
              <div v-if="$v.typeParticipation.$dirty">
                <div class="error" v-if="!$v.typeParticipation.required">
                  Ce champ est requis
                </div>
              </div>
              <v-select
                :items="typeParticipationItems"
                label="Type participation"
                v-model="$v.typeParticipation.$model"
              ></v-select>
            </div>
            <div>
              <div v-if="$v.reservation.$dirty">
                <div class="error" v-if="!$v.reservation.required">
                  Ce champ est requis
                </div>
              </div>
              <v-select
                :items="reservationItems"
                item-text="label"
                item-value="value"
                label="Réservation"
                v-model="$v.reservation.$model"
              ></v-select>
            </div>
            <div>
              <div v-if="$v.avancement.$dirty">
                <div class="error" v-if="!$v.avancement.required">
                  Ce champ est requis
                </div>
              </div>
              <v-select
                v-model="$v.avancement.$model"
                :items="avancementItems"
                label="Avancement"
              ></v-select>
            </div>
            <div>
              <div v-if="$v.responsable.$dirty">
                <div class="error" v-if="!$v.responsable.required">
                  Ce champ est requis
                </div>
                <div class="error" v-if="!$v.responsable.email">
                  Veuillez entrer une adresse mail valide
                </div>
              </div>
              <v-text-field
                v-model="$v.responsable.$model"
                label="Responsable"
              ></v-text-field>
            </div>
            <v-textarea
              v-model="commentaires"
              label="Commentaires"
            ></v-textarea>
            <v-text-field
              v-model="appui"
              label="Avec l'appui de"
            ></v-text-field>
            <v-row
            >
            <v-col><v-btn type="submit">Enregistrer</v-btn></v-col></v-row
          >
          <v-col> <v-btn @click="close">Fermer</v-btn></v-col>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required,email } from "vuelidate/lib/validators";
import DatePickerComponent from "../Dialog/DatePickerComponent.vue"
export default {
  name: "SalonsFormComponent",
  components:{DatePickerComponent},
  props: ["btn_text", "titre", "projet_id", "salon_id", "edit"],
  data: () => ({
    dialog: false,
    salon: null,
    nomSalon: "",
    reservation: "",
    typeParticipation: "",
    date: "",
    avancement: "",
    responsable: "",
    appui: "",
    commentaires: "",
    typeParticipationItems: ["Visiteur", "Exposant"],
    reservationItems: [
      { label: "Oui", value: true },
      { label: "Non", value: false },
    ],
    avancementItems: ["En cours", "En continu", "Non débuté", "Terminé"],
    modalDatePicker: "",
  }),
  validations: {
    nomSalon: {
      required,
    },
    reservation: {
      required,
    },
    typeParticipation: {
      required,
    },
    date: {
      required,
    },
    avancement: {
      required,
    },
    responsable: {
      required,
      email,
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    ...mapActions(["addSalon", "updateSalon"]),
    ...mapGetters(["getSalon"]),
    editLoadSalon() {
      var salon = this.salon;
      this.nomSalon = salon.nom_salon;
      this.date = salon.date_salon;
      this.typeParticipation = salon.type_participation;
      this.reservation = salon.reservation;
      this.avancement = salon.avancement;
      this.responsable = salon.responsable;
      this.appui = salon.appui;
      this.commentaires = salon.commentaires;
    },
    saveForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (!this.$props.edit) {
          this.addNew();
        } else {
          this.update();
        }
        this.close();
      }
    },
    saveDate(date) {
      this.date = date;
    },
    addNew() {
      var salon = {
        nom_salon: this.nomSalon,
        date_salon: this.date,
        type_participation: this.typeParticipation,
        reservation: this.reservation,
        avancement: this.avancement,
        responsable: this.responsable,
        appui: this.appui,
        commentaires: this.commentaires,
        projet: this.$props.projet_id,
      };
      this.addSalon(salon);
    },
    update() {
      this.salon.nom_salon = this.nomSalon;
      this.salon.date_salon = this.date;
      this.salon.type_participation = this.typeParticipation;
      this.salon.reservation = this.reservation;
      this.salon.avancement = this.avancement;
      this.salon.responsable = this.responsable;
      this.salon.appui = this.appui;
      this.salon.commentaires = this.commentaires;
      this.updateSalon(this.salon);
    },

    open() {
      console.log(this.$props.projet_id);
      if (this.$props.edit == true) {
        this.salon=this.getSalon()(this.salon_id)
        this.editLoadSalon();
      }
    },

    close() {
      this.$refs.salonForm.reset();
      this.date = "";
      this.$v.$reset();
      this.dialog = false;
    },
  },
};
</script>