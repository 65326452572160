<template>
  <div>
    <NavMenuComponent :projet_id="projet_id"></NavMenuComponent>
    <div>
      <h1>Diagramme de Gantt</h1>
      <br />
      <v-divider></v-divider>
      <br />
      <div class="col-md-4 print-hide" style="margin: 0 auto">
        <DatePickerComponent v-on:dateSaved="saveDate" label="Date"></DatePickerComponent>
      </div>
      <div v-if="dataFetched == true">
        <div class="gantt">
          <div v-for="objectif in objectifs" :key="objectif.id">
            <v-list>
              <v-list-group :value="true">
                <template v-slot:activator>
                  <v-list-item>
                    <v-list-item-title>
                      {{ objectif.nom_objectif }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-list-item>
                  <v-list-item-content>
                    <GanttTableComponent v-bind:activites="activites" v-bind:taches="taches" v-bind:date_debut="dateDebut">
                    </GanttTableComponent>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </div>
        </div>
        <div v-if="objectifs.length == 0">
          <p>
            Des objectifs n'ont pas encore eté rajoutés a ce projet, utilisez la
            page
            <router-link :to="{ name: 'PlanAction', params: { projet_id: projet_id } }">plan d'action</router-link>
            pour en rajouter
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GanttTableComponent from "./GanttTableComponent";
import DatePickerComponent from "../Dialog/DatePickerComponent.vue";
import NavMenuComponent from "../NavMenuComponent";
import { mapActions } from "vuex";
export default {
  name: "GanttComponent",
  components: { GanttTableComponent, NavMenuComponent, DatePickerComponent },
  props: ["projet_id"],
  data: () => ({
    modalDatePicker: false,
    objectifs: null,
    activites: null,
    taches: null,
    dateDebut: null,
  }),
  computed: {
    dataFetched() {
      return this.objectifs != null && this.activites != null && this.taches != null
    }
  },
  methods: {
    ...mapActions(["fetchObjectifsProjet", "fetchActivitesProjet", "fetchTachesProjet"]),
    saveDate(date) {
      this.dateDebut = date;
    },
  },
  created() {
    this.fetchObjectifsProjet(this.projet_id).then((o) => {
      this.objectifs = o;
    });
    this.fetchActivitesProjet(this.projet_id).then((a) => {
      this.activites = a;
    });
    this.fetchTachesProjet(this.projet_id).then((t) => {
      this.taches = t;
    });
  },
}
</script>

<style scoped>
div.v-list-item__content {
  overflow-x: auto;
}
</style>