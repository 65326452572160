var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataFetched)?_c('v-data-table',{attrs:{"items":_vm.users,"headers":_vm.headers,"search":_vm.search,"custom-filter":_vm.searchFilter,"locale":"fr","no-data-text":"Aucune donnée disponible","no-results-text":"Aucun résultat correspondant","footer-props":{
      'items-per-page-text': 'Lignes par page:',
      'page-text': '{0}-{1} sur {2}',
      'items-per-page-all-text': 'Toutes',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"label":"Recherche"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.projets",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column projetschips"},_vm._l((item.projets),function(projet){return _c('v-chip',{key:projet.id},[_c('router-link',{attrs:{"to":'/planaction/' + projet.id}},[_vm._v(_vm._s(projet.nom_projet))])],1)}),1)]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeUtilisateur(item))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('td',[_c('UserFormComponent',{attrs:{"titre":"Modifier utilisateur","user_id":item.id,"edit":true}}),_c('v-btn',{attrs:{"small":"","color":"red","icon":""},on:{"click":function($event){return _vm.deleteAction(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,false,1254540543)}):_vm._e(),_c('UserFormComponent',{attrs:{"btn_text":"Ajouter Utilisateur","titre":"Nouvel utilisateur","edit":false}}),_c('ConfirmationPromptComponent',{ref:"confirmationPrompt",attrs:{"titre":"Confirmer suppression","text":_vm.promptText,"callback":_vm.promptCallback}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }