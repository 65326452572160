<template>
  <div>
    <NavMenuComponent :projet_id="projet_id"></NavMenuComponent>
    <RapportViewComponent
      v-if="allowed"
      :projet_id="projet_id"
      :type_rapport="type_rapport"
    ></RapportViewComponent>
    <br />
    <br />
    <ProjetAuthorization
      :projet_id="projet_id"
      v-on:isAllowed="isAllowed"
    ></ProjetAuthorization>
  </div>
</template>

<script>
import RapportViewComponent from "@/components/Rapports/RapportViewComponent.vue";
import ProjetAuthorization from "@/components/User/ProjetAuthorization";
import NavMenuComponent from "@/components/NavMenuComponent";
export default {
  name: "RapportView",
  components: { RapportViewComponent, ProjetAuthorization, NavMenuComponent },
  data: () => ({
    allowed: false,
  }),
  props: ["projet_id", "type_rapport"],
  methods: {
    isAllowed(allowed) {
      this.allowed = allowed;
    },
  },
};
</script>

<style scoped>
@media print {
  #navMenu {
    display: none;
  }
}
</style>