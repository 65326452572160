<template>
  <div>
    <v-data-table v-if="dataFetched" :items="rappels" :headers="headers" :search="search" :custom-filter="searchFilter"
      locale="fr" sort-by="date_debut" :sort-desc="true" no-data-text="Aucune donnée disponible"
      no-results-text="Aucun résultat correspondant" :footer-props="{
        'items-per-page-text': 'Lignes par page:',
        'page-text': '{0}-{1} sur {2}',
        'items-per-page-all-text': 'Toutes',
      }">
      <template v-slot:top>
        <v-text-field v-model="search" label="Recherche"></v-text-field>
      </template>

      <template #item.type_objet="{ item }">
        {{ item.objet_rappel }}
      </template>

      <template #item.objet="{ item }">
        {{ getObjetRappel(item) }}
      </template>

      <template #item.type_objet="{ item }">
        {{ item.objet_rappel }}
      </template>

      <template #item.date_derniere_execution="{ item }">
        <td>
          {{ formatDate(item.date_derniere_execution) }}
        </td>
      </template>

      <template #item.date_prochaine_execution="{ item }">
        <td>
          {{ formatDate(item.date_prochaine_execution) }}
        </td>
      </template>

      <template #item.actions="{ item }">
        <td>
          <v-btn small color="red" icon @click="deleteAction(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
    <RappelsFormComponent btn_text="Rappels" titre="Nouveau Rappel" :isFromRappelsPage="true" :projet_id="projet_id">
    </RappelsFormComponent>
    <ConfirmationPromptComponent ref="confirmationPrompt" titre="Confirmer suppression" v-bind:text="promptText"
      v-bind:callback="promptCallback"></ConfirmationPromptComponent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RappelsFormComponent from "./RappelsFormComponent.vue";
import ConfirmationPromptComponent from "../Dialog/ConfirmationPromptComponent.vue";
export default {
  name: "RappelsTableComponent",
  components: {
    RappelsFormComponent,
    ConfirmationPromptComponent,
  },
  data: () => ({
    rappels: null,
    activites: null,
    taches: null,
    search: "",
    promptText: "",
    promptCallback: null,
  }),
  props: ["projet_id"],
  computed: {
    headers() {
      return [
        {
          text: "Nom rappel",
          value: "nom_rappel",
        },
        { text: "Type d'objet du rappel", value: "type_objet" },
        { text: "Objet du rappel", value: "objet" },
        { text: "Destinataire", value: "destinataire" },
        { text: "Date", value: "date_debut" },
        { text: "Heure", value: "heure" },
        { text: "Dernière execution", value: "date_derniere_execution" },
        { text: "Prochaine execution", value: "date_prochaine_execution" },
        { text: "Nombre d'executions", value: "nombre_executions" },
        { text: "Nombre de répetions", value: "nb_repetitions" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    dataFetched() {
      return this.activites != null && this.taches != null && this.rappels != null
    }
  },
  methods: {
    ...mapActions(["fetchRappels", "fetchActivitesProjet", "fetchTachesProjet", "deleteRappel"]),
    ...mapGetters(["getUsers"]),
    searchFilter(value, search, entree) {
      let inNom = RegExp(search, "i").test(entree.nom_rappel);
      let inDestinataire = RegExp(search, "i").test(entree.destinataire);
      return inNom || inDestinataire;
    },
    formatDate(date) {
      if (date != null) {
        var moment = this.$moment;
        moment.locale("fr");
        return moment(date).format("YYYY-MM-DD HH:mm");
      } else return "";
    },
    getObjetRappel(item) {
      var id_objet = item.id_objet_rappel;
      if (item.objet_rappel == "activite") {
        return this.activites.find((a) => a.id = id_objet).nom_activite;
      }

      if (item.objet_rappel == "tache") {
        return this.taches.find((a) => a.id = id_objet).nom_tache;
      }
    },
    deleteAction(item) {
      this.promptText = "Supprimer le rappel " + item.nom_rappel + " ?";
      this.promptCallback = () => {
        this.deleteRappel(item);
      };
      this.$refs.confirmationPrompt.dialog = true;
    },
  },
  created() {
    this.fetchActivitesProjet(this.projet_id).then((a) => {
      this.activites = a;
    });
    this.fetchTachesProjet(this.projet_id).then((t) => {
      this.taches = t;
    });
    this.fetchRappels(this.projet_id).then((rappels) => {
      this.rappels = rappels;
    });
  }
}
</script>