<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      locale="fr"
      v-if="menu"
      v-model="time"
      full-width
      @click:minute="
        $refs.menu.save(time);
        save();
      "
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: "TimePickerComponent",
  props: ["label", "previousTime"],
  data: () => ({
    time: "",
    menu: false,
  }),
  methods: {
    save() {
      this.$emit("timeSaved", this.time);
    },
  },
};
</script>