<template>
<div>
    <NavMenuComponent :projet_id="projet_id"></NavMenuComponent>
    <div class="col-md-10" style="margin:0 auto">
    <h1>Rappels</h1>
    <hr>
    <RappelsTableComponent :projet_id="projet_id"></RappelsTableComponent>
    </div>
</div>
</template>

<script>
import RappelsTableComponent from "@/components/PlanAction/RappelsTableComponent"
import NavMenuComponent from "@/components/NavMenuComponent"
export default {
    name:"RappelsComponent",
    components:{RappelsTableComponent,NavMenuComponent},
    props:["projet_id"]
}
</script>