<template>
<div>
    <div class="banner">
      <v-container>
        <v-row class="bannerContent">
          <v-col md="1"><v-icon >mdi-pencil</v-icon></v-col>
          <v-col md="4"><h2>Hélico en action</h2></v-col>
          <v-col md="7">
            <p>Hélico en action est un service permettant de suivre en temps réel l’avancement des
activités prévues au plan de commercialisation à l’international. Ces plans couvrent en
général une période de 12 à 36 mois.</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <br>
    <br>
</div>
</template>

<script>
export default {
  name: 'HomeComponent'
}
</script>

<style scoped>
  .banner
  {
    background-color: #7191c3;
    width: 100%;
    padding: 50px;
    color: white;
  }

  #app .banner h2
  {
    color: white;
  }

  .banner ul li 
  {
    text-align: left;
  }

  #app .banner .v-icon
  {
    font-size:100px;
    color:white
  }


</style>


