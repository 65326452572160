import axios from "axios"
import { objectifsOrdreUrl, activitesOrdreUrl, tachesOrdreUrl, objectifsUrl, objectifUrl, activitesUrl, activiteUrl, tachesUrl, tacheUrl, convertTacheToActiviteUrl, convertActiviteToTacheUrl, projetObjectifsUrl, projetActivitesUrl, projetTachesUrl } from "../urls"

function updateObject(updated_object, old_object) {
    Object.entries(updated_object).forEach((entry) => {
        let [key, value] = entry
        old_object[key] = value
    })
}

const state = {
    objectifs: null,
    activites: null,
    taches: null,
}

const getters = {
    getObjectifs: (state) => {
        return state.objectifs;
    },
    getObjectif: (state) => (objectif_id) => {
        return state.objectifs.find(o => o.id == objectif_id);
    },
    getActivites: (state) => {
        return state.activites
    },
    getActivite: (state) => (activite_id) => {
        var activiteIndex = state.activites.findIndex(a => a.id == activite_id)
        return state.activites[activiteIndex]
    },
    getTaches: (state) => {
        return state.taches
    },
    getTache: (state) => (tache_id) => {
        var tacheIndex = state.taches.findIndex(t => t.id == tache_id)
        return state.taches[tacheIndex]
    },
    getResponsablesList: (state) => {
        let activites_responsables = state.activites.map((a) => a.responsable)
        let taches_responsables = state.taches.map((t) => t.responsable)
        return [].concat(activites_responsables, taches_responsables)
    }
}

const actions = {
    async fetchObjectifsProjet({ commit }, projet_id) {
        var url = projetObjectifsUrl.replace("{id}", projet_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setObjectifs", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null
        });
        return reponse;
    },

    async fetchActivitesProjet({ commit }, projet_id) {
        var url = projetActivitesUrl.replace("{id}", projet_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setActivites", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null
        });
        return reponse;
    },

    async fetchTachesProjet({ commit }, projet_id) {
        var url = projetTachesUrl.replace("{id}", projet_id);
        const reponse = await axios.get(url).then((reponse) => {
            if (reponse.status == 200) {
                commit("setTaches", reponse.data);
                return reponse.data
            }
            return null
        }).catch((error) => {
            console.log(error)
            return null
        });
        return reponse;
    },

    addObjectif({ commit }, objectif) {
        var url = objectifsUrl.replace("{id}", objectif.projet);
        axios.post(url, objectif).then((response) => {
            if (response.status == 201) {
                var objectif = response.data;
                commit("addObjectif", objectif);
            }
        });
    },

    //eslint-disable-next-line no-unused-vars
    async updateObjectif({ commit }, objectif) {
        var url = objectifUrl.replace("{id}", objectif.id);
        return await axios.put(url, objectif).then((response) => {
            if (response.status == 200) {
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },

    //eslint-disable-next-line no-unused-vars
    async updateOrdreObjectifs({ commit }, data) {
        return await axios.post(objectifsOrdreUrl, data).then((response) => {
            if (response.status == 200) {
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },

    deleteObjectif({ commit }, objectif) {
        var url = objectifUrl.replace("{id}", objectif.id);
        axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteObjectif", objectif);
            }
        });
    },
    addActivite({ commit }, activite) {
        axios.post(activitesUrl, activite).then((response) => {
            if (response.status == 201) {
                var activite = response.data;
                commit("addActivite", activite);
            }
        }).catch(error => {
            console.log(error)
        });
    },

    //eslint-disable-next-line no-unused-vars
    async updateActivite({ commit }, activite) {
        var url = activiteUrl.replace("{id}", activite.id);
        return await axios.put(url, activite).then((response) => {
            if (response.status == 200) {
                var activite = response.data;
                commit("updateActivite", activite);
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },

    //eslint-disable-next-line no-unused-vars
    async updateOrdreActivites({ commit }, data) {
        return await axios.post(activitesOrdreUrl, data).then((response) => {
            if (response.status == 200) {
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },


    async convertActiviteToTache({ commit }, data) {
        const activite = data.activite
        var url = convertActiviteToTacheUrl.replace("{id}", activite.id);
        return await axios.post(url, data.payload).then((response) => {
            if (response.status == 201) {
                commit("addTache", response.data)
                let activite_to_link_taches_to_id = data.payload.activite_to_link_taches_to_id
                if (activite_to_link_taches_to_id != null) {
                    const payload = { activite: activite, new_activite_id: activite_to_link_taches_to_id }
                    commit("setNewActiviteToTaches", payload)
                }
                commit("deleteActivite", activite)
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },

    addTache({ commit }, tache) {
        axios.post(tachesUrl, tache).then((response) => {
            if (response.status == 201) {
                var tacheData = response.data;
                tacheData.objectif = tache.objectif
                commit("addTache", tacheData);
            }
        }).catch(error => {
            console.log(error)
        });
    },

    //eslint-disable-next-line no-unused-vars
    async updateTache({ commit }, tache) {
        var url = tacheUrl.replace("{id}", tache.id);
        return await axios.put(url, tache).then((response) => {
            if (response.status == 200) {
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },

    //eslint-disable-next-line no-unused-vars
    async updateOrdreTaches({ commit }, data) {
        return await axios.post(tachesOrdreUrl, data).then((response) => {
            if (response.status == 200) {
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },

    async convertTacheToActivite({ commit }, data) {
        var url = convertTacheToActiviteUrl.replace("{id}", data.tache.id);
        return await axios.post(url, data.payload).then((response) => {
            if (response.status == 201) {
                commit("addActivite", response.data)
                commit("deleteTache", data.tache)
                return true
            }
            return false
        }).catch(error => {
            console.log(error)
            return false
        });
    },

    deleteTache({ commit }, tache) {
        var url = tacheUrl.replace("{id}", tache.id);
        axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteTache", tache);
            }
        });
    },
    deleteActivite({ commit }, activite) {
        var url = activiteUrl.replace("{id}", activite.id);
        axios.delete(url).then((response) => {
            if (response.status == 200) {
                commit("deleteActivite", activite);
            }
        });
    }

}
const mutations = {
    setObjectifs: (state, objectifs) => { state.objectifs = objectifs },

    setActivites: (state, activites) => { state.activites = activites },

    setTaches: (state, taches) => { state.taches = taches },

    addObjectif: (state, objectif) => {
        state.objectifs.push(objectif);
    },

    updateObjectif: (state, objectif) => {
        var objectifIndex = state.objectifs.find(o => o.id == objectif.id)
        state.objectifs[objectifIndex] = objectif
    },

    deleteObjectif: (state, objectif) => {
        var objectifIndex = state.objectifs.findIndex(o => o.id == objectif.id)
        state.objectifs.splice(objectifIndex, 1);
    },

    addActivite: (state, activite) => {
        state.activites.push(activite);
    },

    updateActivite: (state, updatedActivite) => {
        var activiteIndex = state.activites.findIndex(a => a.id == updatedActivite.id)
        var activite = state.activites[activiteIndex]
        updateObject(updatedActivite, activite)

    },

    addTache: (state, tache) => {
        state.taches.push(tache)
    },

    updateTache: (state, updatedTache) => {
        var tacheIndex = state.taches.findIndex(t => t.id == tache.id)
        var tache = state.taches[tacheIndex]
        updateObject(updatedTache, tache)
    },

    deleteTache: (state, tache) => {
        var tacheIndex = state.taches.findIndex(t => t.id == tache.id);
        state.taches.splice(tacheIndex, 1);
    },

    setNewActiviteToTaches: (state, data) => {
        var new_activite = state.activites.find(a => a.id == data.new_activite_id);
        state.taches = state.taches.map((t) => {
            if (t.activite == data.activite.id)
                t.activite = new_activite.id
            return t
        })
    },

    deleteActivite: (state, activite) => {
        var activiteIndex = state.activites.findIndex(a => a.id == activite.id);
        state.activites.splice(activiteIndex, 1);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}