var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.dataFetched)?_c('v-data-table',{attrs:{"items":_vm.salons,"headers":_vm._headers,"locale":"fr","hide-default-footer":_vm.isRapportMode(),"disable-pagination":_vm.isRapportMode(),"no-data-text":"Aucune donnée disponible","no-results-text":"Aucun résultat correspondant","footer-props":{
        'items-per-page-text': 'Lignes par page:',
        'page-text': '{0}-{1} sur {2}',
        'items-per-page-all-text': 'Toutes',
    }},scopedSlots:_vm._u([{key:"item.reservation",fn:function(ref){
    var item = ref.item;
return [_c('div',{class:_vm.reservationClass(item.reservation)},[_vm._v(" "+_vm._s(_vm._f("reservationLabel")(item.reservation))+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"print-hide"},[_c('SalonsFormComponent',{attrs:{"btn_text":"Modifier","titre":"Modifier salon","edit":true,"salon_id":item.id,"projet_id":_vm.projet_id}}),(_vm.isAdmin())?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.salonDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)]}}],null,false,1217416335)}):_vm._e()],1),(!_vm.isRapportMode())?_c('div',{staticClass:"print-hide"},[_c('br'),_c('br'),_c('v-divider'),_c('br'),_c('div',[_c('SalonsFormComponent',{attrs:{"btn_text":"Ajouter Salon","titre":"Ajouter Salon","edit":false,"projet_id":_vm.projet_id}})],1),_c('div',[_c('ConfirmationPromptComponent',{ref:"confirmationPrompt",attrs:{"titre":"Confirmer suppression","text":_vm.promptText,"callback":_vm.promptCallback}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }